import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../contexts/ProductsFilters';
import OptionsFilter from './OptionsFilter';

const BrandsFilter = () => {
    const [{ selectedBrands, availableBrandFilters }, { addBrandFilter, removeBrandFilter }] = useContext(ProductsFiltersContext);
    if (!availableBrandFilters || Object.keys(availableBrandFilters).length === 0) return null;
    return (
        <OptionsFilter
            label="Marca"
            selectedOptions={Array.from(selectedBrands)}
            availableOptions={availableBrandFilters}
            onChecked={(option) => {
                addBrandFilter(option.toString());
            }}
            onUnchecked={(option) => {
                removeBrandFilter(option.toString());
            }}
        />
    );
};

export default BrandsFilter;
