import { Box, Typography } from '@material-ui/core';

interface FilterProps {
    label: string;
    children: React.ReactNode;
}
const Filter = ({ label, children }: FilterProps) => (
    <Box display="flex" flexDirection="column" justifyContent="start" pb={2}>
        <Typography variant="subtitle1" style={{ marginBottom: 1, fontWeight: 700, fontSize: 14, marginRight: '16px' }}>
            {label}
        </Typography>
        {children}
    </Box>
);

export default Filter;
