import axios from 'axios';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

import API from '../api/API';
import userConstants from '../contexts/User/userConstants';
import { SelectedVariantType } from '../hooks/useSnackbar';
import storageHelper from './storageHelper';

//FIXME: Falta tipar el userContext
const logout = (userContextDispatch: Dispatch<unknown>) => {
    void API.auth.logout();
    userContextDispatch({ type: userConstants.LOGOUT });
    storageHelper.logout();
};

interface HandleErrorParams {
    customErrorMessage?: string;
    showSnackbarMessage: (message: string, variant: SelectedVariantType) => void;

    //El error puede tomar otras formas si no me equivoco (x eso unknown)
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    error?: unknown;
    navigate: NavigateFunction;
    redirectIfNotFound?: boolean;
    redirectToHome?: boolean;
    userContextDispatch: unknown; //FIXME: Falta tipar el userContext
}

const handleError = ({ customErrorMessage, showSnackbarMessage, error, navigate, redirectIfNotFound = false, redirectToHome, userContextDispatch }: HandleErrorParams) => {
    let message = '';
    const isAxiosError = axios.isAxiosError(error);
    if (isAxiosError && error.response?.status === 401) {
        showSnackbarMessage('Su sesión ha caducado. Vuelva a iniciar sesión.', 'warning');
        //FIXME: Falta tipar el userContext
        logout(userContextDispatch as Dispatch<unknown>);
        if (redirectToHome) navigate('/');
    } else if (isAxiosError && error.response?.status === 404 && redirectIfNotFound) {
        navigate('/not-found', { replace: true });
    } else {
        message = 'Network error';
        if (customErrorMessage) message = customErrorMessage;
        showSnackbarMessage(message, 'error');
    }

    console.error(error);

    // Ignoro la advertencia porque no estoy seguro si haría falta algún cambio y no tengo tiempo de investigarlo.
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    return Promise.reject(message);
};

const handler = {
    logout,
    handleError,
};

export default handler;
