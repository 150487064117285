import { Box, CardActionArea } from '@material-ui/core';
import { Link } from 'react-router-dom';

import imageHelper from '../../../helpers/imageHelper';
import ProductImage from './ProductImage';
import useStyles from './styles';

const ProductImageContainer = ({ product, imageMustOpenPreviewModal, setModalPreViewProduct }) => {
    const classes = useStyles();
    const productLink = `/producto/${encodeURIComponent(product.slug)}`;

    if (imageMustOpenPreviewModal) {
        return (
            <CardActionArea onClick={() => setModalPreViewProduct(true)}>
                <Box className={classes.mediaWrapper}>
                    <ProductImage
                        alt={imageHelper.getProductImageAlt(product)}
                        className={classes.media}
                        image={imageHelper.getProductImageSrc(product)}
                        title={product.name.toUpperCase()}
                    />
                </Box>
            </CardActionArea>
        );
    }
    return (
        <Box className={classes.mediaWrapper}>
            <Link to={productLink} style={{ textDecoration: 'none' }}>
                <ProductImage alt={imageHelper.getProductImageAlt(product)} className={classes.media} image={imageHelper.getProductImageSrc(product)} title={product.name.toUpperCase()} />
            </Link>
        </Box>
    );
};

export default ProductImageContainer;
