import { Dispatch, ReactNode } from 'react';

export type ConfigState = Record<string, string | number | boolean | object | null>;

export enum TypeAction {
    FETCH_CONFIGS_SUCCESS = 'FETCH_CONFIGS_SUCCESS',
    FETCH_CONFIGS_ERROR = 'FETCH_CONFIGS_ERROR',
}

export interface Action {
    type: TypeAction.FETCH_CONFIGS_SUCCESS | TypeAction.FETCH_CONFIGS_ERROR;
    config: ConfigState;
}

export interface ConfigProviderProps {
    children: ReactNode;
}

export interface ConfigItem {
    id: string;
    value: string | number | boolean | object | null;
}

export const configKeys = {
    FREE_SHIPPING: 'FREE_SHIPPING',
};

export interface ConfigContextType {
    state: ConfigState;
    dispatch: Dispatch<Action>;
}

export interface ConfigProviderProps {
    children: React.ReactNode;
}
