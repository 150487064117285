import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Params, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ProductsFiltersContext } from '../../contexts/ProductsFilters';
import { helmetConfigForProductTypes } from '../../data/seo';
import { SORT_VALUES, SortValue } from '../../helpers/sortOptionsHelper';
import { ProductType } from '../../types/ProductType';
import { getSlugForProductType, serializeSpecs, unserializeSpecs } from './utils';

interface Props {
    sort?: SortValue;
    setSort: React.Dispatch<SetStateAction<SortValue>>;
}

const useURLFiltersSync = ({ sort, setSort }: Props): boolean => {
    const [syncFromURLDone, setSyncFromURLDone] = useState(false);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const location = useLocation();

    const isSyncingRef = useRef(false);

    const [
        { selectedBrands, selectedPrice, selectedSpecs, filterQ, selectedProductType, offersOnly },
        { setProductType, setBrandFilters, setSpecFilters, setPriceLte, setPriceGte, setFilterQ, clearSpecFilters, clearFilters, setOffersOnly },
    ] = useContext(ProductsFiltersContext);

    const navigate = useNavigate();

    const calcSelectedProductTypeFromParams = ({ productTypeSlug: usedSlug }: Params): ProductType | undefined => {
        if (!usedSlug) return undefined;

        const config = Object.values(helmetConfigForProductTypes).find(({ productTypeSlug }) => usedSlug === productTypeSlug);
        if (!config) return undefined;
        return config.type;
    };

    const changeQFilterBasedOnQuery = () => {
        const q = searchParams.get('q');
        if (q !== null) setFilterQ(q);
    };

    const changeFilteredBrandsBasedOnQuery = () => {
        const brandQueryParamAsArray = searchParams.get('brand')?.split(',') ?? [];
        setBrandFilters(brandQueryParamAsArray);
    };

    const changeFilteredPriceBasedOnQuery = () => {
        const priceFromQuery = {
            min: searchParams.get('min'),
            max: searchParams.get('max'),
        };
        if (Number.isNaN(priceFromQuery.min) || Number.isNaN(priceFromQuery.max)) throw new Error('Price from query is not a number');

        if (priceFromQuery.min === null) setPriceGte(undefined);
        else setPriceGte(Number(priceFromQuery.min));
        if (priceFromQuery.max === null) setPriceLte(undefined);
        else setPriceLte(Number(priceFromQuery.max));
    };

    const changeFilteredSpecsBasedOnQuery = () => {
        try {
            const serializedSpecs = searchParams.get('specs');
            if (!serializedSpecs) clearSpecFilters();
            else {
                const unserializedSpecs = unserializeSpecs(serializedSpecs);
                setSpecFilters(unserializedSpecs);
            }
        } catch (e) {
            console.error(e);
            throw new Error('Error parsing specs from query');
        }
    };

    const changeSortBasedOnQuery = () => {
        const sortFromQuery = searchParams.get('sort');
        if (!sortFromQuery) setSort(SORT_VALUES.popularity);
        else if (sortFromQuery && Object.values(SORT_VALUES).includes(sortFromQuery as SortValue)) setSort(sortFromQuery as SortValue);
        else throw new Error('Sort from query is not a valid sort value');
    };

    const changeProductTypeBasedOnParams = () => {
        const newSelectedType = calcSelectedProductTypeFromParams(params);
        setProductType(newSelectedType);
        if (params.type && !newSelectedType) navigate('/not-found');
    };

    const changeOffersOnlyBasedOnPath = () => {
        const isOffersPath = location.pathname.startsWith('/ofertas');
        setOffersOnly(isOffersPath);
    };

    const updateSelectedFiltersFromURL = () => {
        try {
            changeOffersOnlyBasedOnPath();
            changeProductTypeBasedOnParams();
            changeQFilterBasedOnQuery();
            changeFilteredBrandsBasedOnQuery();
            changeFilteredPriceBasedOnQuery();
            changeFilteredSpecsBasedOnQuery();
            changeSortBasedOnQuery();
            setSyncFromURLDone(true);
        } catch (e) {
            console.error(e);
            navigate({ pathname: 'productos' });
        }
    };
    // Actualizar filtros desde la URL
    useEffect(() => {
        if (isSyncingRef.current) {
            isSyncingRef.current = false; // Evitar doble sincronización
            return;
        }

        try {
            updateSelectedFiltersFromURL();
        } catch (e) {
            console.error(e);
            clearFilters();
            navigate('/productos');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Actualizar la URL desde los filtros
    useEffect(() => {
        if (isSyncingRef.current) return; // Evitar bucles innecesarios

        const newSearchParams = new URLSearchParams();

        if (filterQ) newSearchParams.set('q', filterQ);
        if (selectedPrice.lte) newSearchParams.set('max', selectedPrice.lte.toString());
        if (selectedPrice.gte) newSearchParams.set('min', selectedPrice.gte.toString());
        if (selectedBrands.size > 0) newSearchParams.set('brand', Array.from(selectedBrands).join(','));
        if (Object.keys(selectedSpecs).length > 0) newSearchParams.set('specs', serializeSpecs(selectedSpecs));
        if (sort && sort !== SORT_VALUES.popularity) newSearchParams.set('sort', sort);

        let path = offersOnly ? '/ofertas' : '/productos';
        if (selectedProductType) {
            const productTypeSlug = getSlugForProductType(selectedProductType);
            if (!productTypeSlug) throw new Error('Product type has no slug');
            path += `/${productTypeSlug}`;
        }

        if (newSearchParams.toString()) path += `?${newSearchParams.toString()}`;

        const currentPath = `${location.pathname}${location.search}`;
        if (path !== currentPath) {
            isSyncingRef.current = true; // Indicar que está sincronizando
            navigate(path);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offersOnly, selectedProductType, selectedBrands, selectedPrice, selectedSpecs, sort, filterQ]);

    return syncFromURLDone;
};

export default useURLFiltersSync;
