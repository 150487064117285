import { Box, CircularProgress, IconButton, TextField, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useContext, useState } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import useSnackbarGD from '../../../hooks/useSnackbar';
import { useCouponStyles } from './useStyles';

const CouponCode = () => {
    const classes = useCouponStyles();
    const [isCouponCodeInputOpen, setIsCouponCodeInputOpen] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const { showSnackbarMessage } = useSnackbarGD();

    const handleCouponCodeChange = (e) => setCouponCode(e.target.value);

    const handleCouponCodeButtonClick = async () => {
        if (state.user?.cart?.appliedCoupon?.code === couponCode) {
            showSnackbarMessage('El código ingresado es el ya existente.', 'error');
        } else {
            setIsLoading(true);
            await API.carts
                .applyCoupon({ code: couponCode, id: state.user.cart.id })
                .then((response) => {
                    dispatch({
                        type: userConstants.UPDATE_COUPON,
                        appliedCoupon: response.data.appliedCoupon,
                    });
                    setIsCouponCodeInputOpen(false);
                    showSnackbarMessage('¡Cupón aplicado con éxito! 🎉', 'success');
                })
                .catch((error) => {
                    if (error.response?.data.message) {
                        const variant = error.response?.status === 404 ? 'error' : 'warning';
                        showSnackbarMessage(error.response?.data.message, variant);
                    } else {
                        showSnackbarMessage('Hubo un problema al validar el código. Intenta nuevamente en unos minutos.', 'error');
                    }
                })
                .finally(() => setIsLoading(false));
        }
    };

    const onKeyDownCouponCodeInput = (e) => {
        if (e.keyCode === 13) handleCouponCodeButtonClick();
    };

    return (
        <>
            {isCouponCodeInputOpen && (
                <Box className={classes.couponCodeInputContainer}>
                    <TextField
                        id="code"
                        label="Introducí tu código"
                        variant="outlined"
                        fullWidth
                        onChange={handleCouponCodeChange}
                        className={classes.couponCodeInputTextfield}
                        inputProps={{
                            style: {
                                padding: 10.5,
                            },
                        }}
                        onKeyDown={onKeyDownCouponCodeInput}
                    />

                    <IconButton type="button" className={classes.couponCodeInputIconButton} onClick={handleCouponCodeButtonClick}>
                        {isLoading ? <CircularProgress size={20} /> : <PlayArrowIcon className={classes.couponCodeInputIcon} />}
                    </IconButton>
                </Box>
            )}

            {!isCouponCodeInputOpen && !state.user?.cart?.appliedCoupon && (
                <Box className={classes.couponCodeContainer}>
                    <Typography display="inline" variant="subtitle1">
                        ¿Tenés un cupón?&nbsp;
                    </Typography>
                    <Typography display="inline" variant="subtitle1" className={classes.couponClickHereButton} onClick={() => setIsCouponCodeInputOpen(true)}>
                        Hacé click acá
                    </Typography>
                </Box>
            )}
        </>
    );
};
export default CouponCode;
