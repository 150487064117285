import { Box, useTheme } from '@material-ui/core';
import { Dispatch, SetStateAction, useContext } from 'react';

import { ProductsFiltersContext } from '../../../../../contexts/ProductsFilters';
import FiltersForProductTypeDialogContent from './DialogContent/FiltersForProductTypeDialogContent';
import ProductSelectionDialogContent from './DialogContent/ProductSelectionDialogContent';

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    totalResults?: number;
}

const MobileFiltersDialog = ({ open, setOpen, totalResults }: Props) => {
    const theme = useTheme();

    const [{ selectedProductType }] = useContext(ProductsFiltersContext);

    if (!open) return null;

    return (
        <Box zIndex={theme.zIndex.drawer} position="fixed" top={0} left={0} right={0} bottom={0} bgcolor={'#E5E5E5'}>
            <Box display="flex" maxWidth={'100vw'} maxHeight={'100vh'} flexDirection="column" py={2}>
                {selectedProductType === undefined ? (
                    <ProductSelectionDialogContent setOpen={setOpen} />
                ) : (
                    <FiltersForProductTypeDialogContent setOpen={setOpen} totalResults={totalResults} />
                )}
            </Box>
        </Box>
    );
};

export default MobileFiltersDialog;
