/* eslint-disable max-len */
import {
    Box,
    Button,
    Collapse,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Fragment, useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import API from '../../../../../api/API';
import { INVOICE_TYPES, SHIPPING_ERROR_MESSAGE, SHIPPING_TYPES } from '../../../../../common/constants';
import { useConfig } from '../../../../../contexts/Config/context';
import { configKeys } from '../../../../../contexts/Config/types';
import { UserContext } from '../../../../../contexts/User';
import handler from '../../../../../helpers/handler';
import storageHelper from '../../../../../helpers/storageHelper';
import { useIsVisible } from '../../../../../hooks/customHooks';
import useProvincesAndLocalities from '../../../../../hooks/useProvincesAndLocalities';
import useShippingAgencySelection from '../../../../../hooks/useShippingAgencySelection';
import useSnackbarGD from '../../../../../hooks/useSnackbar';
import colors from '../../../../../theme/colors';
import ShippingAgencyMap from '../ShippingAgencyMap';
import IconToDisplay from './components/IconToDisplay';
import MatchedAgencies from './components/MatchedAgencies';
import { isNullOrUndefined } from './utils';

const useStyles = makeStyles((theme) => ({
    agenciesListContainer: {
        border: '1px solid #F8E837',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
        '& .MuiCollapse-wrapper': {
            width: '100%',
        },
    },
    removeInputControls: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
            margin: 80,
        },
    },
    noMOnMobile: {
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginRight: 0,
            marginTop: 16,
        },
    },
    formContainer: {
        paddingBottom: 30,
    },
    greyButton: {
        backgroundColor: '#DBDBD5',
        color: '#1C1C1C',
    },
    highlightedText: {
        color: colors.black,
        fontWeight: 'bold',
    },
    localitiesList: {
        maxHeight: 263,
        overflow: 'auto',
        width: '100%',
    },
    modalMap: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none',
            height: '0px',
            visiblity: 'hidden',
        },
    },
    pickupAtWarehouseHelper: {
        color: colors.black,
        fontSize: '10px',
    },
    shippingTypes: {
        margin: '0px 0px 20px 14px',
    },
    subTitle: {
        fontSize: '15px',
        fontWeight: 400,
        marginBottom: '6px',
        '@media (max-width: 768px)': {
            fontSize: '14px',
        },
    },
    subTitleHelper: {
        color: '#1C1C1CB2',
        fontSize: '12px',
    },
    title: {
        fontSize: '20px',
        '@media (max-width: 768px)': {
            fontSize: '16px',
        },
    },
    titleContainer: {
        marginBottom: '8px',
    },
    yellowButton: {
        backgroundColor: '#F8E837',
        color: '#1C1C1C',
    },
}));

const Shipping = ({ buyerData, goToNextStep, isDisabled, orderSummaryRef, setIsDisabled, setLoadingShippingPrice, setShippingData, shippingData }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();
    const [state, dispatch] = useContext(UserContext);

    const isOrderSummaryFullyVisible = useIsVisible(orderSummaryRef);

    const { localities, provinces, selectedProvince, setLocalities, setSelectedLocality, setSelectedProvince } = useProvincesAndLocalities({
        initialLocality: shippingData.locality || null,
        initialProvince: shippingData.province || null,
    });

    const { showSnackbarMessage } = useSnackbarGD();

    const [errorQuotingPrice, setErrorQuotingPrice] = useState(false);
    const [openAgenciesModal, setOpenAgenciesModal] = useState(false);
    const [openFindAgenciesDialog, setOpenFindAgenciesDialog] = useState(false);

    const {
        center,
        markers,
        matchedAgencies,
        selectedLocality: selectedAgencyLocality,
        selectedProvince: selectedAgencyProvince,
        uniqueLocalities,
        zoom,
        handleAgenciesDisplay,
        handleChangeProvince,
        onAgencyClick,
        resetShippingAgencySelection,
    } = useShippingAgencySelection({
        initialLocality: shippingData.locality || {},
        initialProvince: shippingData.province || '',
        selectedProvince,
        setShippingData,
        shippingData,
    });

    const isHomeDelivery = shippingData.shippingType === SHIPPING_TYPES.homeDelivery;
    const isPickupAtNearestAgency = shippingData.shippingType === SHIPPING_TYPES.pickupAtNearestAgency;
    const isPickupAtWarehouse = shippingData.shippingType === SHIPPING_TYPES.pickupAtWarehouse;

    const scrollToOrderSummary = () => orderSummaryRef.current.scrollIntoView();

    const checkIfThereIsAPreviousQuote = (data) => Boolean(data.province && data.locality && data.price);

    const toggleAgenciesModalOpen = () => setOpenAgenciesModal(!openAgenciesModal);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setShippingData({ ...shippingData, [name]: value });
    };

    const handleSubmit = () => {
        if (isPickupAtWarehouse) {
            goToNextStep();
        } else if (shippingData.locality && !isNullOrUndefined(shippingData.price)) {
            goToNextStep();
        }
    };

    const toggleFindAgenciesDialogOpen = () => {
        setShippingData({
            ...shippingData,
            address: '',
            codigoPostal: null,
            floor: '',
            locality: null,
        });
        setSelectedLocality(null);
        setOpenFindAgenciesDialog(!openFindAgenciesDialog);
    };

    const handleChangeLocality = (newLocality) => {
        const newShippingData = {
            ...shippingData,
            codigoPostal: newLocality?.codigoPostal,
            locality: newLocality,
        };

        if (newLocality) {
            if (!isOrderSummaryFullyVisible) scrollToOrderSummary();

            setIsDisabled(true);
            setLoadingShippingPrice(true);
            const cart = storageHelper.getUserCart();
            const cartId = cart.id;

            API.shipping
                .quote({ cartId, shippingData: newShippingData })
                .then((response) => {
                    const { price } = response.data;
                    setShippingData({ ...newShippingData, price: isSubsidizedShippingPrice ? 0 : price });
                })
                .catch((error) => {
                    if (error.response.status === 404 && error.response.data.error) {
                        setOpenFindAgenciesDialog(true);
                    } else {
                        handler.handleError({
                            error,
                            userContextDispatch: dispatch,
                            showSnackbarMessage,
                            customErrorMessage: SHIPPING_ERROR_MESSAGE,
                        });
                    }
                })
                .finally(() => {
                    setIsDisabled(false);
                    setLoadingShippingPrice(false);
                });
        }
    };

    const getShippingPrice = async (newShippingData = shippingData) => {
        setLoadingShippingPrice(true);
        setIsDisabled(true);
        try {
            const thereIsShippingDataToQuote = newShippingData.codigoPostal && newShippingData.province && newShippingData.locality;

            const cart = storageHelper.getUserCart();
            const cartId = cart.id;

            if (thereIsShippingDataToQuote) {
                const response = await API.shipping.quote({ cartId, shippingData: newShippingData });
                const { price } = response.data;

                if (response) {
                    setShippingData({
                        ...newShippingData,
                        price: isSubsidizedShippingPrice ? 0 : price,
                    });
                }
            } else {
                setLoadingShippingPrice(false);
                setErrorQuotingPrice(true);
            }
        } catch (error) {
            setErrorQuotingPrice(true);
        } finally {
            setLoadingShippingPrice(false);
            setIsDisabled(false);
        }
    };

    const handleShippingTypeChange = async (newShippingType) => {
        const newShippingData = {
            ...shippingData,
            shippingType: newShippingType,
            province: null,
            locality: null,
            address: '',
            floor: '',
            codigoPostal: null,
            price: null,
            useTelegramForTracking: false,
        };

        setShippingData(newShippingData);

        if (newShippingType !== SHIPPING_TYPES.pickupAtWarehouse && shippingData.province && shippingData.locality) {
            await getShippingPrice(newShippingData);
        }

        if (isHomeDelivery || isPickupAtNearestAgency) {
            if (isHomeDelivery) {
                setLocalities([]);
                setSelectedLocality(null);
                setSelectedProvince(null);
            }

            resetShippingAgencySelection();
        }
    };

    useEffect(() => {
        const updatedShippingData = {
            ...shippingData,
            phone: shippingData.phone || buyerData.phone,
        };

        if (buyerData.invoiceType === INVOICE_TYPES.finalConsumer) {
            updatedShippingData.firstName = shippingData.firstName || buyerData.firstName;
            updatedShippingData.lastName = shippingData.lastName || buyerData.lastName;
            updatedShippingData.dni = shippingData.dni || buyerData.dni;
        }

        setShippingData(updatedShippingData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickSelectAgency = () => {
        toggleAgenciesModalOpen();
        toggleFindAgenciesDialogOpen();
        handleShippingTypeChange(SHIPPING_TYPES.pickupAtNearestAgency);
    };

    const displayMatchedAgencies = (localityId, agencies, localityName) => {
        if (selectedAgencyLocality[localityId] !== true) return null;

        const agenciesToDisplay = agencies.filter((agency) => agency.name === localityName);

        return <MatchedAgencies agencies={agenciesToDisplay} province={selectedAgencyProvince} shippingData={shippingData} onAgencyClick={onAgencyClick} />;
    };

    const setUserLocalityBasedOnProfile = async () => {
        const { locality, province } = shippingData;

        setSelectedLocality(locality);
        setSelectedProvince(province);

        if (isHomeDelivery || isPickupAtNearestAgency) await getShippingPrice();
    };

    useEffect(() => {
        if (shippingData && !checkIfThereIsAPreviousQuote(shippingData) && !errorQuotingPrice && !shippingData.shippingType) {
            setUserLocalityBasedOnProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorQuotingPrice, setLoadingShippingPrice, setShippingData, shippingData, state.user.shippingData]);

    return (
        <>
            <ValidatorForm autoComplete="off" id="shippingForm" onSubmit={handleSubmit}>
                <Container maxWidth="xl">
                    <Box className={classes.noMOnMobile}>
                        <Typography className={classes.title} variant="h1">
                            Datos de envío
                        </Typography>
                    </Box>
                    <Typography className={classes.subTitle} variant="h2">
                        Tipo de envío
                    </Typography>
                    <RadioGroup className={classes.shippingTypes} name="shippingType" onChange={(event) => handleShippingTypeChange(event.target.value)} value={shippingData.shippingType}>
                        <FormControlLabel
                            className={isHomeDelivery ? classes.highlightedText : ''}
                            control={<Radio size="small" color="primary" />}
                            label="Envío a domicilio"
                            value={SHIPPING_TYPES.homeDelivery}
                        />
                        <FormControlLabel
                            className={isPickupAtNearestAgency ? classes.highlightedText : ''}
                            control={<Radio size="small" color="primary" />}
                            label="Retiro en agencia más cercana"
                            value={SHIPPING_TYPES.pickupAtNearestAgency}
                        />
                        <FormControlLabel
                            className={isPickupAtWarehouse ? classes.highlightedText : ''}
                            control={<Radio size="small" color="primary" />}
                            label={
                                <>
                                    Retiro en depósito (CABA - Microcentro)
                                    {isPickupAtWarehouse && (
                                        <Typography className={classes.pickupAtWarehouseHelper}>
                                            Solicitar turno y dirección al{' '}
                                            <Link href="https://wa.me/+5491180310399" target="_blank" underline="always">
                                                WhatsApp
                                            </Link>
                                        </Typography>
                                    )}
                                </>
                            }
                            value={SHIPPING_TYPES.pickupAtWarehouse}
                        />
                    </RadioGroup>
                    {isHomeDelivery && (
                        <>
                            <Box className={classes.titleContainer}>
                                <Typography variant="h4" className={classes.subTitle} style={{ margin: 0 }}>
                                    Envío a domicilio
                                </Typography>
                                <Typography variant="subtitle2" className={classes.subTitleHelper}>
                                    Recordá completar con los datos de la <span className={classes.highlightedText}>persona que recibe el pedido</span>
                                </Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <Autocomplete
                                        autoComplete={false}
                                        disabled={isDisabled}
                                        disableClearable
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => value.name === option.name}
                                        onChange={(_, newProvince) => {
                                            setSelectedProvince(newProvince);
                                            setSelectedLocality(null);
                                            setShippingData({
                                                ...shippingData,
                                                locality: null,
                                                province: newProvince,
                                            });
                                        }}
                                        options={provinces}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                errorMessages={['Ingresa la provincia de quien recibe.']}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'no-autocomplete',
                                                }}
                                                label="Provincia*"
                                                validators={['required']}
                                                value={shippingData.province}
                                            />
                                        )}
                                        value={shippingData.province}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Autocomplete
                                        autoComplete={false}
                                        disableClearable
                                        disabled={!shippingData.province || isDisabled}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => value.name === option.name}
                                        onChange={(_, newLocality) => {
                                            setSelectedLocality(newLocality);
                                            handleChangeLocality(newLocality);
                                        }}
                                        options={localities}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                errorMessages={['Ingresa la localidad de quien recibe.']}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'no-autocomplete',
                                                }}
                                                label="Localidad*"
                                                validators={['required']}
                                                value={shippingData.locality}
                                            />
                                        )}
                                        value={shippingData.locality}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator
                                        disabled={isDisabled}
                                        errorMessages={['Ingresa la calle y número de quien recibe.']}
                                        fullWidth
                                        id="address"
                                        label="Calle y número*"
                                        name="address"
                                        onChange={handleChange}
                                        validators={['required']}
                                        value={shippingData.address}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator disabled={isDisabled} fullWidth id="floor" label="Piso/Depto" name="floor" onChange={handleChange} value={shippingData.floor} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {isPickupAtNearestAgency && (
                        <>
                            <Box className={classes.titleContainer}>
                                <Typography variant="h4" className={classes.subTitle} style={{ margin: 0 }}>
                                    Retiro en agencia más cercana
                                </Typography>
                                <Typography variant="subtitle2" className={classes.subTitleHelper}>
                                    {typeof selectedAgencyProvince === 'string' ? 'Elegí un punto de retiro' : 'Retirás en'}
                                </Typography>
                            </Box>
                            <Grid container spacing={2} direction={isMobile ? 'column-reverse' : 'row'}>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.formContainer}>
                                        <Autocomplete
                                            autoComplete={false}
                                            disableClearable
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => value.name === option.name}
                                            options={provinces}
                                            onChange={(_, newProvince) => handleChangeProvince(newProvince)}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    errorMessages={['Selecciona la provincia']}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'no-autocomplete',
                                                    }}
                                                    label="Provincia*"
                                                    validators={['required']}
                                                    value={shippingData.province}
                                                />
                                            )}
                                            value={shippingData.province}
                                        />
                                    </Box>
                                    <Box className={classes.formContainer}>
                                        <Autocomplete id="disabled" disabled renderInput={(params) => <TextField {...params} label="Localidad*" variant="standard" />} />
                                        <Box className={classes.localitiesList}>
                                            <List component="localities">
                                                {uniqueLocalities.map((locality) => (
                                                    <Fragment key={locality.id}>
                                                        <ListItem button onClick={() => handleAgenciesDisplay({ locality })} selected>
                                                            <ListItemText primary={locality.name} />
                                                            <IconToDisplay loading={selectedAgencyLocality[locality.id]} />
                                                        </ListItem>
                                                        <Collapse in={selectedAgencyLocality[locality.id]} maxWidth="xl" className={classes.agenciesListContainer} unmountOnExit>
                                                            {displayMatchedAgencies(locality.id, matchedAgencies, locality.name)}
                                                        </Collapse>
                                                    </Fragment>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box className={classes.formContainer}>
                                        <ShippingAgencyMap center={center} className={classes.modalMap} markers={markers} zoom={zoom} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {Boolean(shippingData.shippingType) && (
                        <Box sx={{ mb: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator
                                        errorMessages={[`Ingresa el nombre de quien ${isHomeDelivery ? 'recibe' : 'retira'}.`]}
                                        fullWidth
                                        id="firstName"
                                        label={`Nombre de quien ${isHomeDelivery ? 'recibe' : 'retira'}*`}
                                        name="firstName"
                                        onChange={handleChange}
                                        validators={['required']}
                                        value={shippingData.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator
                                        errorMessages={[`Ingresa el apellido de quien ${isHomeDelivery ? 'recibe' : 'retira'}.`]}
                                        fullWidth
                                        id="lastName"
                                        label={`Apellido de quien ${isHomeDelivery ? 'recibe' : 'retira'}*`}
                                        name="lastName"
                                        onChange={handleChange}
                                        validators={['required']}
                                        value={shippingData.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator
                                        errorMessages={[`Ingresa el documento de quien ${isHomeDelivery ? 'recibe' : 'retira'}.`]}
                                        fullWidth
                                        id="dni"
                                        label={`DNI de quien ${isHomeDelivery ? 'recibe' : 'retira'}*`}
                                        name="dni"
                                        onChange={handleChange}
                                        validators={['required']}
                                        value={shippingData.dni}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextValidator
                                        errorMessages={[`Ingresa el teléfono de quien ${isHomeDelivery ? 'recibe' : 'retira'}.`]}
                                        fullWidth
                                        id="phone"
                                        InputProps={{ classes: { root: classes.removeInputControls } }}
                                        label={`Teléfono de quien ${isHomeDelivery ? 'recibe' : 'retira'}*`}
                                        name="phone"
                                        onChange={handleChange}
                                        type="number"
                                        validators={['required']}
                                        value={shippingData.phone}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Container>
            </ValidatorForm>
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                disableEscapeKeyDown
                onClose={toggleFindAgenciesDialogOpen}
                open={openFindAgenciesDialog}
            >
                <DialogTitle id="alert-dialog-title">Envío puerta a puerta</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Lamentablemente no hacemos envíos puerta a puerta a tu dirección, pero te lo podemos enviar a una agencia cercana
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleFindAgenciesDialogOpen} className={classes.greyButton}>
                        Volver atrás
                    </Button>
                    <Button autoFocus className={classes.yellowButton} onClick={handleClickSelectAgency}>
                        Seleccionar Agencia
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Shipping;
