import { Box, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';
import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../contexts/ProductsFilters';
import { isCategory } from '../../../helpers/productTypesHelper';
import { ProductType } from '../../../types/ProductType';
import { ProductTypeOrCategoryDefinition as ProductTypeDefinitionOrCategory } from '../../../types/ProductTypeFrontendDefinition';
import StyledTreeItem from './StyledTreeItem';
import { useFilterButtonsStyles } from './styles';

interface Props {
    items: ProductTypeDefinitionOrCategory[];
    onProductTypeSelected?: (productType: ProductType) => void;
}

const ProductTypesFilterButtons = ({ items, onProductTypeSelected }: Props) => {
    const classes = useFilterButtonsStyles();
    const [, { setProductType }] = useContext(ProductsFiltersContext);

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            {items.map((item, index) =>
                isCategory(item) ? (
                    <TreeView key={item.title} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                        <StyledTreeItem nodeId={index.toString()} labelText={item.title}>
                            {item.productTypes.map((productTypeDef, subIndex) => (
                                <StyledTreeItem
                                    nodeId={`${index.toString()}-${subIndex.toString()}`}
                                    key={productTypeDef.title}
                                    onClick={() => {
                                        setProductType(productTypeDef.type);
                                        onProductTypeSelected?.(productTypeDef.type);
                                    }}
                                    labelText={productTypeDef.title}
                                />
                            ))}
                        </StyledTreeItem>
                    </TreeView>
                ) : (
                    <Button
                        key={item.title}
                        onClick={() => {
                            setProductType(item.type);
                            onProductTypeSelected?.(item.type);
                        }}
                        className={classes.filterSpecButton}
                    >
                        {item.title}
                    </Button>
                )
            )}
        </Box>
    );
};

export default ProductTypesFilterButtons;
