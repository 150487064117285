import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { SpecValue } from '../../../../contexts/ProductsFilters/types';
import Filter from './Filter';

interface Props {
    label: string;
    availableOptions?: Record<string, SpecValue>;
    selectedOptions: SpecValue[];
    onChecked: (optionKey: SpecValue) => void;
    onUnchecked: (optionKey: SpecValue) => void;
}

const OptionsFilter = ({ label, availableOptions, selectedOptions, onChecked, onUnchecked }: Props) => {
    if (!availableOptions) return null;

    return (
        <Filter label={label}>
            {Object.entries(availableOptions).map(([optionLabel, optionKey]) => (
                <FormControlLabel
                    key={optionKey.toString()}
                    style={{ marginLeft: 3 }}
                    control={
                        <Checkbox
                            name={optionKey.toString()}
                            onChange={(e) => {
                                if (e.target.checked) onChecked(optionKey);
                                else onUnchecked(optionKey);
                            }}
                            checked={selectedOptions.includes(optionKey)}
                        />
                    }
                    label={
                        <Typography
                            style={{
                                marginTop: 6,
                                marginLeft: 6,
                                marginBottom: 4,
                                color: '#727272',
                            }}
                            variant="subtitle1"
                        >
                            {optionLabel}
                        </Typography>
                    }
                />
            ))}
        </Filter>
    );
};

export default OptionsFilter;
