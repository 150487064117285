import { Box, Button, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import GDTheme from '../../../theme/GDTheme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 8,
        paddingBottom: 12,
        minHeight: 112,
    },
    stepChangeButton: {
        margin: GDTheme().spacing(1),
        minWidth: 'auto',
        '@media (max-width: 1000px)': {
            maxWidth: '50%',
        },
    },
    containerHeader: {
        width: '100%',
        '@media (max-width: 1200px)': {
            width: '90%',
        },
    },
}));

const ProBuilderHeader = ({ icon, title, description, lastStepDisabled, goToPreviousStep, goToNextStep, goToNextAllowed, lastStep, isSubmitting }) => {
    const classes = useStyles();
    const buttons = (
        <>
            <Button
                variant="outlined"
                onClick={goToPreviousStep}
                disabled={lastStepDisabled}
                className={classes.stepChangeButton}
                startIcon={<NavigateBefore style={{ fontSize: '22px' }} />}
            >
                Anterior
            </Button>
            <Button
                variant="contained"
                onClick={goToNextStep}
                disabled={!goToNextAllowed || (lastStep && isSubmitting)}
                className={classes.stepChangeButton}
                endIcon={<NavigateNext style={{ fontSize: '22px' }} />}
            >
                {lastStep ? 'Finalizar' : 'Siguiente'}
            </Button>
        </>
    );
    return (
        <>
            <Hidden smDown>
                <Box ml={3}>
                    <Paper style={{ backgroundColor: '#F1F1F1', width: '100%' }} elevation={0}>
                        <Grid container spacing={0} className={classes.root}>
                            <Hidden mdDown>
                                <Grid item xs={1}>
                                    <Box pl={2} pt={2}>
                                        <img src={icon} alt="Icono" />
                                    </Box>
                                </Grid>
                            </Hidden>
                            <Grid item xs={7}>
                                <Box display="flex" flexDirection="column" alignItems="baseline" pt={2} pl={1} className={classes.containerHeader}>
                                    <Typography variant="h5">{title}</Typography>
                                    {description}
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center" pt={2}>
                                    {buttons}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display="flex" justifyContent="center" pt={2}>
                    {buttons}
                </Box>
            </Hidden>
        </>
    );
};

export default ProBuilderHeader;
