import { SortDefinition } from '../api/types';

export const SORT_VALUES = {
    popularity: 'popularity',
    higherPrice: 'higherPrice',
    lowerPrice: 'lowerPrice',
} as const;
export type SortValue = (typeof SORT_VALUES)[keyof typeof SORT_VALUES];

const sortOptions: Record<SortValue, SortDefinition> = {
    [SORT_VALUES.popularity]: { field: 'salesQuantity', order: 'DESC' },
    [SORT_VALUES.higherPrice]: { field: 'price.special.ARS', order: 'DESC' },
    [SORT_VALUES.lowerPrice]: { field: 'price.special.ARS', order: 'ASC' },
};

export const createSortObject = (sortValue?: SortValue): SortDefinition => {
    if (!sortValue) return sortOptions[SORT_VALUES.popularity];
    return sortOptions[sortValue];
};
