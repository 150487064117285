import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../contexts/ProductsFilters';
import MinMaxFilter from './MinMaxFilter';

const PriceFilter = () => {
    const [{ selectedPrice }, { setPriceGte, setPriceLte }] = useContext(ProductsFiltersContext);
    return <MinMaxFilter label="Precio" selectedValue={selectedPrice} setValueGte={setPriceGte} setValueLte={setPriceLte} />;
};

export default PriceFilter;
