import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../contexts/ProductsFilters';
import { SpecValue } from '../../../../contexts/ProductsFilters/types';
import { OptionalLteGte } from '../../../../types/OptionalLteGte';
import { SpecType } from '../../../../types/SpecType';
import BooleanOptionsFilter from './BooleanFilter';
import MinMaxFilter from './MinMaxFilter';
import OptionsFilter from './OptionsFilter';

interface SpecFilterProps {
    label: string;
    specKey: string;
    type: SpecType;
    availableOptions?: Record<string, SpecValue>;
}

const SpecFilter = ({ label, specKey, type, availableOptions }: SpecFilterProps) => {
    const [, { getSelectedValuesForSpec, addSpecFilter, removeSpecValueFilter, setSpecGteValue, setSpecLteValue }] = useContext(ProductsFiltersContext);
    switch (type) {
        case 'boolean': {
            return (
                <BooleanOptionsFilter
                    label={label}
                    selectedOptions={getSelectedValuesForSpec(specKey) as boolean[]}
                    onChecked={(checkedOption) => {
                        addSpecFilter({ specKey, specValue: checkedOption });
                    }}
                    onUnchecked={(uncheckedOption) => {
                        removeSpecValueFilter({ specKey, specValue: uncheckedOption });
                    }}
                />
            );
        }
        case 'number': {
            return (
                <MinMaxFilter
                    label={label}
                    selectedValue={getSelectedValuesForSpec(specKey) as OptionalLteGte}
                    setValueGte={(gte) => {
                        setSpecGteValue(specKey, gte);
                    }}
                    setValueLte={(lte) => {
                        setSpecLteValue(specKey, lte);
                    }}
                />
            );
        }
        case 'options': {
            return (
                <OptionsFilter
                    label={label}
                    availableOptions={availableOptions}
                    selectedOptions={getSelectedValuesForSpec(specKey) as string[]}
                    onChecked={(checkedOption) => {
                        addSpecFilter({ specKey, specValue: checkedOption });
                    }}
                    onUnchecked={(uncheckedOption) => {
                        removeSpecValueFilter({ specKey, specValue: uncheckedOption });
                    }}
                />
            );
        }
        default:
            return null;
    }
};

export default SpecFilter;
