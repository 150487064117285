import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../contexts/ProductsFilters';
import BrandsFilter from './Filter/BrandsFilter';
import PriceFilter from './Filter/PriceFilter';
import SpecFilters from './Filter/SpecsFilters';

const ProductFiltersForProductType = () => {
    const [{ selectedProductType }] = useContext(ProductsFiltersContext);

    if (!selectedProductType) return null;

    return (
        <>
            <BrandsFilter />
            <SpecFilters />
            <PriceFilter />
        </>
    );
};

export default ProductFiltersForProductType;
