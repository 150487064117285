import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../theme/colors';

const useStyles = makeStyles((theme) => ({
    resume: {
        background: colors.grey,
        color: colors.black,
        minWidth: 300,
        paddingBottom: '20px',
        paddingLeft: '30px',
        paddingRight: '18px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
        },
    },
    totalDivider: { backgroundColor: '#1C1C1C' },
    products: {
        fontWeight: 300,
    },
    details: {
        paddingTop: '0px !important',
    },
    detailsButton: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemPhoto: {
        height: 59,
        width: 58,
    },
    itemDescription: {
        fontWeight: 400,
    },
    itemPrice: {
        fontWeight: 700,
    },
    itemQuantity: {
        fontWeight: 600,
        marginLeft: 5,
    },
    freeShippingContainer: {
        display: 'flex',
        background: colors.grey,
        color: colors.black,
        padding: '1px 18px 20px 30px',
        marginTop: '32px',
    },
    freeShippingTextsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    freeShippingText: {
        width: '184px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '37px',
        fontWeight: 700,
        marginTop: '5px',
    },
}));

export default useStyles;

export const useCouponStyles = makeStyles(() => ({
    couponCodeInputContainer: {
        display: 'flex',
        maxHeight: '38px',
        width: '100%',
    },
    couponCodeInputTextfield: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            height: '38px',
        },
        '& .MuiInputLabel-outlined[data-shrink=false]': {
            transform: 'translate(12px, 12px) scale(1)',
        },
    },
    couponCodeInputIconButton: {
        background: colors.black,
        width: '38px',
        height: '38px',
        padding: '0px',
        borderRadius: 0,
    },
    couponCodeInputIcon: {
        color: colors.yellow,
        padding: 0,
    },
    couponCodeContainer: {
        alignItems: 'center',
        background: colors.lightGrey,
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 25px 9px',
    },
    couponClickHereButton: {
        fontWeight: 700,
        textDecoration: 'underline',
        padding: 0,
        cursor: 'pointer',
    },
    totalSubItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0px',
    },
    couponDivider: { backgroundColor: '#DBDBD5' },
}));
