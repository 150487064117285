import pc1 from '../resources/completeOrder/components/pc1.png';
import { ProductTypes } from './types';

const multiSelectSteps = [ProductTypes.RAM, ProductTypes.STORAGE, ProductTypes.CASE_FAN];

const createBuildFilter = (buildSpecs, stepName) => {
    const filter = [];
    const stepIndex = Object.keys(buildSpecs).indexOf(stepName);
    Object.keys(buildSpecs).forEach((key, productIndex) => {
        if (productIndex < stepIndex && buildSpecs[key]) {
            if (multiSelectSteps.includes(key)) {
                buildSpecs[key].forEach((element) => {
                    const proBuilderItem = {
                        id: element.id,
                        type: element.type,
                        quantity: element.quantity,
                    };
                    filter.push(proBuilderItem);
                });
            } else if (key !== 'cpuBrand' && key !== 'peripherals') {
                const proBuilderItem = {
                    id: buildSpecs[key].id,
                    type: buildSpecs[key].type,
                    quantity: buildSpecs[key].quantity,
                };
                filter.push(proBuilderItem);
            }
        }
    });
    return filter;
};

const getPeriphereals = (build) => {
    const periphereals = [];
    if (build.specs.mouse.products.length > 0) {
        periphereals.push(build.specs.mouse.products);
    }
    if (build.specs.keyboard.products.length > 0) {
        periphereals.push(build.specs.keyboard.products);
    }
    if (build.specs.monitor.products.length > 0) {
        periphereals.push(build.specs.monitor.products);
    }
    return periphereals.flat();
};

const buildInitialState = {
    cpuBrand: null,
    [ProductTypes.MOTHER]: null,
    [ProductTypes.CPU]: null,
    [ProductTypes.CPU_COOLER]: null,
    [ProductTypes.RAM]: [],
    [ProductTypes.GPU]: null,
    [ProductTypes.STORAGE]: [],
    [ProductTypes.CASE]: null,
    [ProductTypes.POWER]: null,
    [ProductTypes.CASE_FAN]: [],
    peripherals: [],
    id: null,
};

const formatProductsArrayToBuild = (productsArray) => {
    const build = buildInitialState;

    productsArray.forEach((product) => {
        const { type } = product;
        if (type === ProductTypes.CPU) build.cpuBrand = product.specs.brand;

        if (multiSelectSteps.includes(type)) build[type] = [...build[type], product];
        else build[type] = product;
    });

    return build;
};

const getProductsFromBuild = (build) => ({
    cpuBrand: build.specs.cpu_brand,
    [ProductTypes.MOTHER]: build.specs.mother.products[0],
    [ProductTypes.CPU]: build.specs.cpu.products[0],
    [ProductTypes.CPU_COOLER]: build.specs.cpuCooler.products[0],
    [ProductTypes.RAM]: build.specs.ram.products,
    [ProductTypes.GPU]: build.specs.gpu.products[0],
    [ProductTypes.STORAGE]: build.specs.storage.products,
    [ProductTypes.CASE]: build.specs.case.products[0],
    [ProductTypes.POWER]: build.specs.power.products[0],
    [ProductTypes.CASE_FAN]: build.specs.fans?.products,
    peripherals: getPeriphereals(build),
    id: build.id,
});

const formatBuilds = ({ builds, cartItems }) => {
    const buildsToShow = builds.map((build) => {
        const soSelected = cartItems.find((item) => item.buildId === build.buildSubItemIndex);
        const getPrice = (typeOfPrice) => {
            if (soSelected) {
                return build.price[typeOfPrice] + soSelected.price[typeOfPrice];
            }
            return build.price[typeOfPrice];
        };
        return {
            id: build.id,
            buildSubItemIndex: build.buildSubItemIndex,
            code: build.code,
            name: build.name,
            price: {
                special: getPrice('special'),
                list: getPrice('list'),
            },
            quantity: 1,
            image: build.image || pc1,
            type: 'build',
            SO: !!soSelected,
            customizable: build.customizable,
        };
    });
    return buildsToShow;
};

const formatProBuilds = ({ uniqueBuildIds, proBuildProducts }) => {
    const proBuildsToShow = uniqueBuildIds.map((id) => {
        let buildListPrice = 0;
        let buildSpecialPrice = 0;
        proBuildProducts.forEach((product) => {
            if (product.buildId === id) {
                buildListPrice += product.price?.list * product.quantity;
                buildSpecialPrice += product.price?.special * product.quantity;
            }
        });
        const soSelected = proBuildProducts.find((product) => product.type === 'operative_system' && product.buildId === id);
        return {
            code: id,
            products: proBuildProducts,
            name: 'PC armada en el probuilder',
            price: {
                special: buildSpecialPrice,
                list: buildListPrice,
            },
            buildId: id,
            quantity: 1,
            image: pc1,
            build: true,
            SO: !!soSelected,
            customizable: true,
        };
    });
    return proBuildsToShow;
};

const formatProductsWithBuilds = (cartItems = []) => {
    const builds = cartItems.filter((product) => !product.buildId && product.type === 'build');
    const easyBuildsIds = builds.map((build) => build.buildSubItemIndex);
    const products = cartItems.filter((product) => !product.buildId && product.type !== 'build');
    const proBuildProducts = cartItems.filter((product) => product.buildId && !easyBuildsIds.includes(product.buildId));
    const proBuildsIds = [];
    cartItems.forEach((product) => {
        if (product.buildId && !easyBuildsIds.includes(product.buildId)) {
            proBuildsIds.push(product.buildId);
        }
    });

    const uniqueBuildIds = [...new Set(proBuildsIds)];

    const buildsToShow = formatBuilds({ builds, cartItems });
    const proBuildsToShow = formatProBuilds({ proBuildProducts, uniqueBuildIds });

    if (proBuildsToShow && proBuildsToShow.length !== 0) products.push(...proBuildsToShow);
    if (buildsToShow && buildsToShow.length !== 0) products.push(...buildsToShow);

    return products;
};

const proBuildHelper = {
    createBuildFilter,
    formatProductsWithBuilds,
    getProductsFromBuild,
    formatProductsArrayToBuild,
    buildInitialState,
    multiSelectSteps,
};

export default proBuildHelper;
