import { Box, Grid, Typography } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { useConfig } from '../../../contexts/Config/context';
import { configKeys } from '../../../contexts/Config/types';
import colors from '../../../theme/colors';
import useStyles from './useStyles';

const FreeShippingBanner = ({ showFreeShippingBanner }) => {
    const classes = useStyles();
    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();

    if (!showFreeShippingBanner || !isSubsidizedShippingPrice) return null;

    return (
        <Grid container className={classes.freeShippingContainer}>
            <Grid item xs={3} className={classes.freeShippingTextsContainer}>
                &nbsp;
                <LocalShippingIcon style={{ fontSize: '44px' }} />
            </Grid>
            <Grid item xs={9} className={classes.freeShippingTextsContainer}>
                &nbsp;
                <Box className={classes.freeShippingText}>
                    <Typography variant="h4" style={{ color: colors.green }}>
                        ENVÍO GRATIS
                    </Typography>
                </Box>
                <Typography variant="h5" style={{ fontWeight: 300 }}>
                    Por &nbsp;
                    <Typography variant="h5" style={{ fontWeight: 400, display: 'inline' }}>
                        Via Cargo
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default FreeShippingBanner;
