import { Box, Button, FormControl } from '@material-ui/core';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import API from '../../../api/API';
import { UserContext } from '../../../contexts/User';
import handler from '../../../helpers/handler';
import useSnackbarGD from '../../../hooks/useSnackbar';
import useStyles from './useStyles';

const CartButtons = ({ showCartButtons, handleClearCart }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbarMessage } = useSnackbarGD();
    const [state, dispatch] = useContext(UserContext);

    const handleFinish = () => {
        if (state.user?.role) {
            API.users.me().catch(() => {
                handler.logout(dispatch);
                showSnackbarMessage('Su sesión ha caducado. Vuelva a iniciar sesión.', 'warning');
            });
        }
    };

    if (!showCartButtons) return null;

    return (
        <>
            <Box mt={4} mb={3}>
                <FormControl fullWidth>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/completeOrder"
                        classes={{ disabled: classes.disabledButton }}
                        disabled={state.user?.cart?.items?.length === 0}
                        onClick={handleFinish}
                    >
                        Finalizar compra
                    </Button>
                </FormControl>
            </Box>
            <Box mb={2}>
                <FormControl fullWidth>
                    <Button variant="outlined" type="submit" onClick={() => navigate('/productos')}>
                        Agregar más productos
                    </Button>
                </FormControl>
            </Box>
            <Box mb={3}>
                <FormControl fullWidth>
                    <Button
                        style={{
                            border: 'none',
                            outline: 'none',
                        }}
                        onClick={handleClearCart}
                    >
                        Limpiar carrito
                    </Button>
                </FormControl>
            </Box>
        </>
    );
};

export default CartButtons;
