import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

const keyboardTypes: KeyValue[] = [
    { key: 'mechanical', value: 'Mecánico' },
    { key: 'membrane', value: 'Membrana' },
    { key: 'hybrid', value: 'Hibrido' },
];

const keyboardSwitches: KeyValue[] = [
    { key: 'red', value: 'Red' },
    { key: 'brown', value: 'Brown' },
    { key: 'blue', value: 'Blue' },
    { key: 'black', value: 'Black' },
    { key: 'green', value: 'Green' },
    { key: 'silver', value: 'Silver' },
];

const keyboardBacklights: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'fixedColor', value: 'Color Fijo' },
    { key: 'noBacklight', value: 'Sin retroiluminación' },
];

const keyboardConnectivities: KeyValue[] = [
    { key: 'wired', value: 'Cableado' },
    { key: 'wireless', value: 'Inalámbrico' },
];

const keyboardSizes: KeyValue[] = [
    { key: 'fullSize', value: 'Full Size' },
    { key: 'tkl', value: 'TKL' },
    { key: '60', value: 'Compacto 60%' },
    { key: '65', value: 'Compacto 65%' },
    { key: '70', value: 'Compacto 70%' },
    { key: 'others', value: 'Otros' },
];

export default {
    type: keyboardTypes,
    switch: keyboardSwitches,
    backlight: keyboardBacklights,
    connectivity: keyboardConnectivities,
    programmableKeys: boolean,
    size: keyboardSizes,
};
