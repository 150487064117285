import { Box } from '@material-ui/core';
import { useContext } from 'react';

import { ProductsFiltersContext } from '../../contexts/ProductsFilters';
import FiltersAsChips from './components/FiltersAsChips';
import ProductFiltersForProductType from './components/ProductFiltersForProductType';
import ProductTypeSelection from './components/ProductTypeSelection';
import SelectedProductType from './components/SelectedProductType';

interface Props {
    totalResults?: number;
}

const DesktopProductFilters = ({ totalResults }: Props) => {
    const [{ selectedProductType }, { clearFiltersKeepingOffers }] = useContext(ProductsFiltersContext);

    const handleProductTypeUnselected = () => {
        clearFiltersKeepingOffers();
    };

    return (
        <Box pl={3} pt={3} bgcolor={'#E5E5E5'} minHeight={'80vh'}>
            {selectedProductType === undefined ? (
                <ProductTypeSelection />
            ) : (
                <>
                    <SelectedProductType onProductTypeUnselected={handleProductTypeUnselected} productType={selectedProductType} totalResults={totalResults} />
                    <FiltersAsChips minLines={1} />
                    <ProductFiltersForProductType />
                </>
            )}
        </Box>
    );
};

export default DesktopProductFilters;
