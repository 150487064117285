import { ProductTypes, peripheralTypesDefinitions, productTypesDefinitions } from '../../../helpers/types';
import { ProductTypeOrCategoryDefinition, ProductsCategoryDefinition } from '../../../types/ProductTypeFrontendDefinition';

const componentProductTypesDefinitions = [
    ProductTypes.CASE,
    ProductTypes.CPU,
    ProductTypes.MOTHER,
    ProductTypes.POWER,
    ProductTypes.RAM,
    ProductTypes.STORAGE,
    ProductTypes.GPU,
    ProductTypes.CPU_COOLER,
    ProductTypes.CASE_FAN,
    ProductTypes.THERMAL_PASTE,
].map((type) => productTypesDefinitions[type]);

const refrigerationCategory: ProductsCategoryDefinition = {
    title: 'Refrigeración',
    productTypes: [productTypesDefinitions[ProductTypes.CPU_COOLER], productTypesDefinitions[ProductTypes.CASE_FAN], productTypesDefinitions[ProductTypes.THERMAL_PASTE]],
};

const firstSection = [...componentProductTypesDefinitions, refrigerationCategory];
const secondSection = peripheralTypesDefinitions;
const thirdSection = [ProductTypes.BUILD].map((type) => productTypesDefinitions[type]);

interface FiltersSections {
    title: string;
    items: ProductTypeOrCategoryDefinition[];
}
export const productTypeFilterSections: FiltersSections[] = [
    { items: firstSection, title: 'Componentes de PC' },
    { items: secondSection, title: 'Periféricos' },
    { items: thirdSection, title: 'Pc armadas' },
];
