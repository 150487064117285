import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../contexts/ProductsFilters';
import SpecFilter from './SpecsFilter';

const SpecFilters = () => {
    const [{ availableSpecFilters }] = useContext(ProductsFiltersContext);
    return (
        <>
            {availableSpecFilters &&
                Object.entries(availableSpecFilters).map(([specKey, specDefinition]) => (
                    <SpecFilter key={specKey} label={specDefinition.label} specKey={specKey} type={specDefinition.type} availableOptions={specDefinition.options} />
                ))}
        </>
    );
};

export default SpecFilters;
