import { Box } from '@material-ui/core';
import { useContext } from 'react';

import { UserContext } from '../../../contexts/User';
import Total from './Total';
import TotalWithCoupon from './TotalWithCoupon';

const TotalPrice = ({ shippingData, loadingTotalPrice, selectedPaymentMode }) => {
    const [state] = useContext(UserContext);

    return (
        <Box mt={1} mb={2}>
            {state.user?.cart?.appliedCoupon ? (
                <TotalWithCoupon shippingCost={shippingData?.price} loadingTotalPrice={loadingTotalPrice} selectedPaymentMode={selectedPaymentMode} />
            ) : (
                <Total shippingCost={shippingData?.price} loadingTotalPrice={loadingTotalPrice} selectedPaymentMode={selectedPaymentMode} />
            )}
        </Box>
    );
};

export default TotalPrice;
