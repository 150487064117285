import { Box, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useContext, useMemo } from 'react';

import { ProductsFiltersContext } from '../../../../../../contexts/ProductsFilters';
import { productTypesDefinitions } from '../../../../../../helpers/types';
import { getTotalResultsText } from '../../../../utils';

interface SelectedFilterIndicatorProps {
    totalResults?: number;
}

const SelectedFilterIndicator = ({ totalResults }: SelectedFilterIndicatorProps) => {
    const [{ offersOnly, selectedProductType }, { clearFilters }] = useContext(ProductsFiltersContext);

    const text = useMemo(() => {
        let result = '';
        if (offersOnly) {
            result += 'Ofertas 🔥';
        }
        if (selectedProductType) {
            const productType = productTypesDefinitions[selectedProductType].title;
            result += offersOnly ? ' / ' + productType : productType;
        }
        return result;
    }, [offersOnly, selectedProductType]);

    if (!selectedProductType && !offersOnly) return null;
    return (
        <Box display="flex" alignItems="center" py={1}>
            <IconButton onClick={clearFilters} size="small">
                <ClearIcon />
            </IconButton>
            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{text}</Typography>
            <Typography variant="caption" color="textSecondary" style={{ fontSize: 12, marginLeft: 5 }}>
                {getTotalResultsText(totalResults)}
            </Typography>
        </Box>
    );
};

export default SelectedFilterIndicator;
