import { FiltersAction, FiltersState, SpecFilters, SpecKeyValue, SpecValue, filtersInitialState } from './types';

const appendSpecValueToSet = (filters: SpecFilters, specToAdd: SpecKeyValue): SpecFilters => {
    const { specKey, specValue } = specToAdd;
    const currentSpecValues = filters[specKey] ?? new Set<SpecValue>();
    return {
        ...filters,
        [specKey]: new Set(currentSpecValues as Set<SpecValue>).add(specValue),
    };
};
const removeSpecValueFromSet = (filters: SpecFilters, specToRemove: SpecKeyValue): SpecFilters => {
    const { specKey, specValue } = specToRemove;
    if (!filters[specKey]) return filters;
    const currentSpecValues = new Set(filters[specKey] as Set<SpecValue>);
    currentSpecValues.delete(specValue);
    if (currentSpecValues.size === 0) {
        const newFilters = { ...filters };
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newFilters[specKey];
        return newFilters;
    }
    return {
        ...filters,
        [specKey]: currentSpecValues,
    };
};

export default (state: FiltersState, action: FiltersAction): FiltersState => {
    switch (action.type) {
        case 'SET_SPEC_FILTER_GTE': {
            const { specKey, gte } = action.payload;
            return {
                ...state,
                selectedSpecs: {
                    ...state.selectedSpecs,
                    [specKey]: { ...state.selectedSpecs[specKey], gte },
                },
            };
        }
        case 'SET_SPEC_FILTER_LTE': {
            const { specKey, lte } = action.payload;
            return {
                ...state,
                selectedSpecs: {
                    ...state.selectedSpecs,
                    [specKey]: { ...state.selectedSpecs[specKey], lte },
                },
            };
        }
        case 'SET_AVAILABLE_SPEC_FILTERS':
            return {
                ...state,
                availableSpecFilters: action.payload,
            };
        case 'SET_AVAILABLE_BRAND_FILTERS':
            return {
                ...state,
                availableBrandFilters: action.payload,
            };
        case 'SET_OFFERS_ONLY':
            return {
                ...state,
                offersOnly: action.payload,
            };
        case 'SET_SPEC_FILTERS': {
            return {
                ...state,
                selectedSpecs: action.payload,
            };
        }
        case 'ADD_SPEC_VALUE_FILTER':
            return {
                ...state,
                selectedSpecs: appendSpecValueToSet(state.selectedSpecs, action.payload),
            };
        case 'REMOVE_SPEC_VALUE_FILTER':
            return {
                ...state,
                selectedSpecs: removeSpecValueFromSet(state.selectedSpecs, action.payload),
            };
        case 'RESET_FILTERS':
            return filtersInitialState;
        case 'RESET_FILTERS_KEEPING_OFFERS':
            return {
                ...filtersInitialState,
                offersOnly: state.offersOnly,
            };
        case 'SET_FILTER_Q':
            return {
                ...state,
                filterQ: action.payload,
            };
        case 'CLEAR_FILTER_Q':
            return {
                ...state,
                filterQ: undefined,
            };
        case 'SET_PRICE_FILTER_LTE':
            return {
                ...state,
                selectedPrice: {
                    ...state.selectedPrice,
                    lte: action.payload,
                },
            };
        case 'SET_PRICE_FILTER_GTE':
            return {
                ...state,
                selectedPrice: {
                    ...state.selectedPrice,
                    gte: action.payload,
                },
            };
        case 'CLEAR_PRICE_FILTER':
            return {
                ...state,
                selectedPrice: { gte: undefined, lte: undefined },
            };
        case 'SET_PRODUCT_TYPE':
            return {
                ...state,
                selectedProductType: action.payload,
            };
        case 'SET_BRAND_FILTERS':
            return {
                ...state,
                selectedBrands: new Set(action.payload),
            };
        case 'ADD_BRAND_FILTER':
            return {
                ...state,
                selectedBrands: new Set(state.selectedBrands).add(action.payload),
            };
        case 'REMOVE_BRAND_FILTER': {
            const newBrands = new Set(state.selectedBrands);
            newBrands.delete(action.payload);
            return {
                ...state,
                selectedBrands: newBrands,
            };
        }
        case 'REMOVE_SPEC_FILTER': {
            const newSpecs = { ...state.selectedSpecs };
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete newSpecs[action.payload];
            return {
                ...state,
                selectedSpecs: newSpecs,
            };
        }
        default: {
            console.error('Unknown action', action);
            return state;
        }
    }
};
