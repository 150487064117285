import { Box, Chip } from '@material-ui/core';
import { useContext, useMemo } from 'react';

import { ProductsFiltersContext } from '../../../contexts/ProductsFilters';
import { SpecValue } from '../../../contexts/ProductsFilters/types';
import { OptionalLteGte } from '../../../types/OptionalLteGte';

interface Chip {
    value: string;
    onDelete: () => void;
}

type FormatFunction = (value: number) => string;

const selectedMinMaxAsChipText = (selectedValue: OptionalLteGte, format?: FormatFunction) => {
    const formatOrStringify = (value: number) => (format ? format(value) : value.toString());
    const gte = selectedValue.gte !== undefined ? formatOrStringify(selectedValue.gte) : undefined;
    const lte = selectedValue.lte !== undefined ? formatOrStringify(selectedValue.lte) : undefined;
    if (gte && lte) return `${gte} a ${lte}`;
    if (gte !== undefined) return `Desde ${gte}`;
    if (lte !== undefined) return `Hasta ${lte}`;

    if (selectedValue.gte && selectedValue.lte) return `${selectedValue.gte.toString()} a ${selectedValue.lte.toString()}`;
    if (selectedValue.gte) return `Desde ${selectedValue.gte.toString()}`;
    if (selectedValue.lte) return `Hasta ${selectedValue.lte.toString()}`;
    return '';
};

interface Props {
    minLines?: number;
}

const FiltersAsChips = ({ minLines }: Props) => {
    const [
        { selectedBrands, selectedProductType, filterQ, selectedPrice, selectedSpecs, availableSpecFilters, offersOnly },
        { removeBrandFilter, clearFilterQ, removeSpecValueFilter, removeSpecFilter, clearPriceFilter, setOffersOnly },
    ] = useContext(ProductsFiltersContext);

    const onDeleteSpecValueChip = (specKey: string, specValue: SpecValue) => {
        removeSpecValueFilter({ specKey, specValue: specValue });
    };

    const onDeleteNumberSpecChip = (specKey: string) => {
        removeSpecFilter(specKey);
    };

    const chips: Chip[] = useMemo((): Chip[] => {
        const newChips: Chip[] = [];
        if (offersOnly)
            newChips.push({
                value: '🔥 Ofertas',
                onDelete: () => {
                    setOffersOnly(false);
                },
            });
        if (filterQ) newChips.push({ value: filterQ, onDelete: clearFilterQ });

        if (selectedBrands.size > 0) {
            selectedBrands.forEach((brand) => {
                newChips.push({
                    value: brand,
                    onDelete: () => {
                        removeBrandFilter(brand);
                    },
                });
            });
        }

        if (selectedPrice.gte || selectedPrice.lte) {
            newChips.push({ value: selectedMinMaxAsChipText(selectedPrice, (price) => `$${price.toString()}`), onDelete: clearPriceFilter });
        }

        Object.entries(selectedSpecs).forEach(([key, value]) => {
            const specDefinition = availableSpecFilters?.[key];
            if (!specDefinition) return;
            if (specDefinition.type === 'number')
                newChips.push({
                    value: `${specDefinition.label}: ${selectedMinMaxAsChipText(value as OptionalLteGte)}`,
                    onDelete: () => {
                        onDeleteNumberSpecChip(key);
                    },
                });
            else {
                (value as SpecValue[]).forEach((specValue) => {
                    let chipLabel = Object.entries(specDefinition.options ?? {}).find(([, optionKey]) => optionKey === specValue)?.[0] ?? specValue.toString();

                    if (specDefinition.type === 'boolean') {
                        chipLabel = `${specDefinition.label}: ${specValue === true ? 'Sí' : 'No'}`;
                    }
                    newChips.push({
                        value: chipLabel,
                        onDelete: () => {
                            onDeleteSpecValueChip(key, specValue);
                        },
                    });
                });
            }
        });
        return newChips;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offersOnly, selectedBrands, selectedProductType, filterQ, selectedPrice, selectedSpecs, availableSpecFilters]);

    return (
        <Box mt={2} display="flex" flexWrap="wrap" justifyContent="between" minHeight={(minLines ?? 0) * 36}>
            {chips.map(({ value, onDelete }) => (
                <Box key={value} pr={1} pb={1} maxWidth={'100%'}>
                    <Chip label={value} onDelete={onDelete} style={{ maxWidth: '100%' }} />
                </Box>
            ))}
        </Box>
    );
};

export default FiltersAsChips;
