import { Box, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import API from '../../../../../api/API';
import { maximumDefaultPrice, minimumDefaultPrice } from './constants';

const useStyles = makeStyles(() => ({
    box: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 605,
    },
}));

const BudgetSelect = ({ buildSpecs, updateBuildSpecs }) => {
    const [marks, setMarks] = useState([]);
    const classes = useStyles();
    const handleChange = (_, value) => updateBuildSpecs({ budget: value });

    const updateMarks = async () => {
        try {
            const params = {
                cpuBrand: buildSpecs.cpuBrand,
                games: buildSpecs.games.map(({ id }) => id),
            };

            const { data } = await API.builds.easyBuilderFind(params);

            const buildsResult = data.data;
            const buildsResultSorted = buildsResult.sort((a, b) => {
                if (a.price.special.ARS > b.price.special.ARS) return 1;

                if (a.price.special.ARS < b.price.special.ARS) return -1;

                return 0;
            });

            const minimumPrice = buildsResultSorted.at(0)?.price.special.ARS || minimumDefaultPrice;
            const maximumPrice = buildsResultSorted.at(-1)?.price.special.ARS || maximumDefaultPrice;
            const middlePrice = (maximumPrice + minimumPrice) / 2;

            const newMarks = [minimumPrice, middlePrice, maximumPrice].map((value) => ({
                label: value.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                }),
                value,
            }));

            setMarks(newMarks);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        updateMarks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box fontWeight="fontWeightLight">
                Definí tu presupuesto máximo para que podamos
                <br />
                elegir los componentes adecuados
            </Box>
            <Box className={classes.box} my={14} mx={2} display="flex">
                {Boolean(marks.length) && (
                    <Slider
                        aria-labelledby="discrete-slider-always"
                        marks={marks}
                        max={marks.at(-1).value}
                        min={marks.at(0).value}
                        onChange={handleChange}
                        step={(marks.at(-1).value - marks.at(0).value) / 2}
                        value={buildSpecs.budget}
                        valueLabelDisplay="off"
                    />
                )}
            </Box>
        </>
    );
};

export default BudgetSelect;
