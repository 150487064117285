import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

const formFactors: KeyValue[] = [
    { key: 'E-ATX', value: 'E-ATX' },
    { key: 'ATX', value: 'ATX' },
    { key: 'M-ATX', value: 'M-ATX' },
    { key: 'ITX', value: 'ITX' },
];

export default {
    formFactors,
    integratedWifi: boolean,
};
