import { ReactNode, createContext, useReducer } from 'react';

import { OptionalLteGte } from '../../types/OptionalLteGte';
import { ProductType } from '../../types/ProductType';
import productsFiltersReducer from './reducer';
import { ContextValue, SpecFilterDefinition, SpecFilters, SpecKeyValue, SpecValue, filtersInitialState } from './types';

export const ProductsFiltersContext = createContext([filtersInitialState, {}] as ContextValue);

export const ProductsFiltersProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(productsFiltersReducer, filtersInitialState);

    const getFiltersQuantity = () => {
        const specsFilters = Object.values(state.selectedSpecs).reduce((acc, value) => {
            if (value instanceof Set) {
                return acc + value.size;
            }
            return acc + 1;
        }, 0);
        const brandsFilters = state.selectedBrands.size;
        const priceFilter = state.selectedPrice.gte || state.selectedPrice.lte ? 1 : 0;
        const filterQ = state.filterQ ? 1 : 0;
        return specsFilters + brandsFilters + priceFilter + filterQ;
    };

    const clearSpecFilters = () => {
        dispatch({ type: 'SET_SPEC_FILTERS', payload: {} });
    };
    const setAvailableSpecFilters = (specFilters: Record<string, SpecFilterDefinition>) => {
        dispatch({ type: 'SET_AVAILABLE_SPEC_FILTERS', payload: specFilters });
    };
    const setSpecFilters = (specFilters: SpecFilters) => {
        dispatch({ type: 'SET_SPEC_FILTERS', payload: specFilters });
    };
    const removeSpecFilter = (specKey: string) => {
        dispatch({ type: 'REMOVE_SPEC_FILTER', payload: specKey });
    };
    const addSpecFilter = (specKeyValue: SpecKeyValue) => {
        dispatch({ type: 'ADD_SPEC_VALUE_FILTER', payload: specKeyValue });
    };
    const removeSpecValueFilter = (specKeyValue: SpecKeyValue) => {
        dispatch({ type: 'REMOVE_SPEC_VALUE_FILTER', payload: specKeyValue });
    };
    const clearFilters = () => {
        dispatch({ type: 'RESET_FILTERS' });
    };

    const clearFiltersKeepingOffers = () => {
        dispatch({ type: 'RESET_FILTERS_KEEPING_OFFERS' });
    };

    const getSelectedValuesForSpec = (specKey: string): SpecValue[] | OptionalLteGte => {
        const result = state.selectedSpecs[specKey];
        if (!result) return [];
        if (result instanceof Set) return Array.from(result);
        return result;
    };

    const setAvailableBrandFilters = (brands: Record<string, string>) => {
        dispatch({ type: 'SET_AVAILABLE_BRAND_FILTERS', payload: brands });
    };
    const addBrandFilter = (brand: string) => {
        dispatch({ type: 'ADD_BRAND_FILTER', payload: brand });
    };
    const removeBrandFilter = (brand: string) => {
        dispatch({ type: 'REMOVE_BRAND_FILTER', payload: brand });
    };
    const setProductType = (productType?: ProductType) => {
        dispatch({ type: 'SET_PRODUCT_TYPE', payload: productType });
    };
    const setFilterQ = (filterQ?: string) => {
        dispatch({ type: 'SET_FILTER_Q', payload: filterQ });
    };
    const clearFilterQ = () => {
        dispatch({ type: 'CLEAR_FILTER_Q' });
    };

    const setBrandFilters = (brands: string[]) => {
        dispatch({ type: 'SET_BRAND_FILTERS', payload: brands });
    };

    const setPriceLte = (lte?: number) => {
        dispatch({ type: 'SET_PRICE_FILTER_LTE', payload: lte });
    };
    const setPriceGte = (gte?: number) => {
        dispatch({ type: 'SET_PRICE_FILTER_GTE', payload: gte });
    };
    const clearPriceFilter = () => {
        dispatch({ type: 'CLEAR_PRICE_FILTER' });
    };

    const setOffersOnly = (offersOnly: boolean) => {
        dispatch({ type: 'SET_OFFERS_ONLY', payload: offersOnly });
    };

    const setSpecGteValue = (specKey: string, gte?: number) => {
        dispatch({ type: 'SET_SPEC_FILTER_GTE', payload: { specKey, gte } });
    };
    const setSpecLteValue = (specKey: string, lte?: number) => {
        dispatch({ type: 'SET_SPEC_FILTER_LTE', payload: { specKey, lte } });
    };
    return (
        <ProductsFiltersContext.Provider
            value={[
                state,
                {
                    getFiltersQuantity,
                    setProductType,
                    setOffersOnly,
                    clearFilters,
                    clearFiltersKeepingOffers,

                    setFilterQ,
                    clearFilterQ,

                    setAvailableSpecFilters,
                    clearSpecFilters,
                    setSpecFilters,
                    addSpecFilter,
                    removeSpecValueFilter,
                    removeSpecFilter,

                    setAvailableBrandFilters,
                    setBrandFilters,
                    addBrandFilter,
                    removeBrandFilter,

                    getSelectedValuesForSpec,

                    setPriceLte,
                    setPriceGte,
                    clearPriceFilter,

                    setSpecGteValue,
                    setSpecLteValue,
                },
            ]}
        >
            {children}
        </ProductsFiltersContext.Provider>
    );
};
