import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';

import { SORT_VALUES, SortValue } from '../../../helpers/sortOptionsHelper';
import { useOrderSelectStyles } from './styles';

interface Props {
    sort: SortValue;
    setSort: Dispatch<SetStateAction<SortValue>>;
}

const OrderSelect = ({ sort, setSort }: Props) => {
    const classes = useOrderSelectStyles();
    return (
        <Box display="flex" justifyContent="flex-end">
            <FormControl className={classes.formControl}>
                <Typography variant="subtitle2" className={classes.orderSelectTitle}>
                    {'Ordenar por: '}
                </Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    autoWidth
                    value={sort}
                    onChange={(e) => {
                        setSort(e.target.value as SortValue);
                    }}
                    inputProps={{
                        className: classes.selectInput,
                    }}
                    disableUnderline
                    className={classes.sortSelect}
                >
                    <MenuItem value={SORT_VALUES.popularity}>Los más populares</MenuItem>
                    <MenuItem value={SORT_VALUES.higherPrice}>Mayor precio</MenuItem>
                    <MenuItem value={SORT_VALUES.lowerPrice}>Menor precio</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default OrderSelect;
