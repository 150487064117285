import { SpecValue } from '../../../../contexts/ProductsFilters/types';
import OptionsFilter from './OptionsFilter';

const booleanOptions = { Sí: true, No: false };

interface BooleanOptionsFilterProps {
    label: string;
    selectedOptions: SpecValue[];
    onChecked: (checkedOption: SpecValue) => void;
    onUnchecked: (uncheckedOption: SpecValue) => void;
}
const BooleanOptionsFilter = ({ label, selectedOptions, onChecked, onUnchecked }: BooleanOptionsFilterProps) => (
    <OptionsFilter label={label} availableOptions={booleanOptions} selectedOptions={selectedOptions} onChecked={onChecked} onUnchecked={onUnchecked} />
);
export default BooleanOptionsFilter;
