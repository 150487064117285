import { Box, Button, Container, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import API from '../../api/API';
import { SHIPPING_ERROR_MESSAGE, SHIPPING_TYPES } from '../../common/constants';
import { useConfig } from '../../contexts/Config/context';
import { configKeys } from '../../contexts/Config/types';
import { UserContext } from '../../contexts/User';
import userConstants from '../../contexts/User/userConstants';
import cartHelper from '../../helpers/cartHelper';
import handler from '../../helpers/handler';
import shippingDataHelper from '../../helpers/shippingDataHelper';
import storageHelper from '../../helpers/storageHelper';
import useSnackbarGD from '../../hooks/useSnackbar';
import colors from '../../theme/colors';
import LoadingButton from '../LoadingButton';
import ShippingAgencySelectionModal from '../completeOrder/components/steps/ShippingAgencySelectionModal';

const useStyles = makeStyles((theme) => ({
    resume: {
        minHeight: 55,
        width: 614,
        background: colors.grey,
        color: colors.black,
        marginLeft: 32,
        minWidth: 300,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: '96%',
            flexDirection: 'column',
            marginTop: 24,
            marginLeft: 8,
            marginRight: 8,
            paddingLeft: 32,
            paddingRight: 32,
            height: 80,
            justifyContent: 'center',
            minWidth: 'auto',
        },
    },
    resumeTypography: {
        fontSize: 16,
    },
    agencyNotFoundModal: {
        width: 683,
        height: 263,
        background: '#ffffff',
        [theme.breakpoints.down('md')]: {
            height: 325,
        },
    },
    rememberMeLabel: {
        lineHeight: 1.5,
    },
    headerBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeButton: {
        height: 14,
        width: 14,
        color: colors.blackGrey,
    },
    stepChangeButton: {
        height: 40,
    },
    agencyNotFoundButton: {
        lineHeight: 1.5,
        fontWeight: 700,
        width: 240,
        height: 34,
        cursor: 'pointer',
    },
    selectButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    localityNotFoundContainer: {
        marginTop: 5,
        paddingTop: 0,
        width: 180,
        height: 31,
    },
    changeLocalityButton: {
        fontWeight: 700,
        marginLeft: 32,
        paddingLeft: 16,
        cursor: 'pointer',
        marginTop: 8,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 8,
            paddingLeft: 32,
        },
    },
    productModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    yellowButton: {
        backgroundColor: '#F8E837!important',
        color: '#1C1C1C!important',
    },
    greyButton: {
        backgroundColor: '#DBDBD5!important',
        color: '#1C1C1C!important',
    },
}));

const ProductShippingQuoteModalBody = forwardRef(function ProductShippingQuoteModalBody(
    { toggleProductShippingQuoteModal, shippingData, setShippingData, productId, initialShippingDataForAgencyChange },
    ref
) {
    const classes = useStyles();
    const availableProvinces = cartHelper.getProvinces();
    const { showSnackbarMessage } = useSnackbarGD();
    const [, setDoorToDoorMode] = useState(true);
    const [selectedLocality, setSelectedLocality] = useState(shippingData?.locality ?? '');
    const [selectedProvince, setSelectedProvince] = useState(shippingData?.province ?? '');
    const [availableLocalities, setAvailableLocalities] = useState([]);
    const [agenciesModal, setAgenciesModal] = useState(false);
    const [openFindAgenciesDialog, setOpenFindAgenciesDialog] = useState(false);
    const [loadingQuote, setLoadingQuote] = useState(false);
    const [seeChooseAddress, setSeeChooseAddress] = useState(true);
    const [state, dispatch] = useContext(UserContext);
    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();

    const toggleAgenciesModalOpen = () => {
        setAgenciesModal(!agenciesModal);
    };

    useEffect(() => {
        const callShippingDataHelper = async () => {
            const localities = await shippingDataHelper.getAvailableLocalities(selectedProvince);
            if (localities) setAvailableLocalities(localities);
        };
        callShippingDataHelper();
    }, [selectedProvince]);

    const toggleFindAgenciesDialogOpen = () => {
        setOpenFindAgenciesDialog(!openFindAgenciesDialog);
    };

    const handleChangeProvince = (newProvince) => {
        const newShippingData = {
            ...shippingData,
            address: '',
            codigoPostal: null,
            floor: '',
            locality: null,
            province: newProvince,
            shippingType: SHIPPING_TYPES.homeDelivery,
        };
        setSelectedProvince(newProvince);
        if (state?.user) {
            const { user } = state;
            const updatedUser = { ...user, shippingData: newShippingData };
            dispatch({ type: userConstants.SET_USER, user: updatedUser });
            storageHelper.setUser(updatedUser);
        }
    };

    const handleChangeLocality = (newLocality) => {
        const newShippingData = {
            ...shippingData,
            address: '',
            codigoPostal: newLocality.codigoPostal,
            floor: '',
            locality: newLocality,
            shippingType: SHIPPING_TYPES.homeDelivery,
        };
        if (newLocality) {
            setLoadingQuote(true);
            const cart = storageHelper.getUserCart();
            const cartId = cart.id;

            API.shipping
                .quote({ productId, cartId, shippingData: newShippingData })
                .then((response) => {
                    const { price } = response.data;
                    setShippingData({
                        ...newShippingData,
                        price: isSubsidizedShippingPrice ? 0 : price,
                        isSubsidizedShippingPrice: isSubsidizedShippingPrice,
                    });
                    if (state?.user) {
                        const { user } = state;
                        const updatedUser = { ...user, shippingData: newShippingData };
                        dispatch({ type: userConstants.SET_USER, user: updatedUser });
                        storageHelper.setUser(updatedUser);
                    }
                    setSeeChooseAddress(false);
                })
                .catch((error) => {
                    if (error.response.status === 404 && error.response.data.error) {
                        toggleFindAgenciesDialogOpen(true);
                    } else {
                        handler.handleError({
                            error,
                            userContextDispatch: dispatch,
                            showSnackbarMessage,
                            customErrorMessage: SHIPPING_ERROR_MESSAGE,
                        });
                    }
                })
                .finally(() => setLoadingQuote(false));
        }
    };

    const handleCalculateShipping = () => {
        if (selectedLocality) handleChangeLocality(selectedLocality);
    };

    const handleShippingPricePreview = () => {
        setSeeChooseAddress(true);
        toggleProductShippingQuoteModal(false);
    };

    return (
        <div className={classes.agencyNotFoundModal} ref={ref}>
            <Box mt={4} mb={2} mx={3} className={classes.headerBox}>
                <Typography variant="h5">Calcular costo de envío</Typography>
                <IconButton onClick={() => toggleProductShippingQuoteModal(false)} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {seeChooseAddress ? (
                <Container maxWidth="xl">
                    <ValidatorForm id="shippingForm" autoComplete="off">
                        <Grid container spacing={2} className={classes.formContainer}>
                            <Grid item xs={12} lg={6}>
                                <Box>
                                    <Autocomplete
                                        className={classes.inputAutocomplete}
                                        value={selectedProvince}
                                        autoComplete={false}
                                        disableClearable
                                        options={availableProvinces}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newProvince) => {
                                            handleChangeProvince(newProvince);
                                            setShippingData(
                                                shippingDataHelper.getShippingDataForProvinceChange({
                                                    newProvince,
                                                    shippingData,
                                                })
                                            );
                                            setSelectedLocality(null);
                                        }}
                                        getOptionSelected={(option, value) => value.name === option.name}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                value={selectedProvince}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'no-autocomplete',
                                                }}
                                                label="Provincia"
                                                margin="none"
                                                validators={['required']}
                                                errorMessages="Seleccione la provincia"
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} lg={6} ml={1}>
                                <Box>
                                    <Autocomplete
                                        className={classes.inputAutocomplete}
                                        autoComplete={false}
                                        disableClearable
                                        options={availableLocalities}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedLocality}
                                        onChange={(event, newLocality) => {
                                            setSelectedLocality(newLocality);
                                        }}
                                        getOptionSelected={(option, value) => value.name === option.name}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                value={selectedProvince}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'no-autocomplete',
                                                }}
                                                label="Localidad"
                                                margin="none"
                                            />
                                        )}
                                    />
                                    <Box width={200} pt={1} className={classes.localityNotFoundContainer}>
                                        <Typography variant="subtitle2" className={classes.rememberMeLabel}>
                                            ¿No encontrás tu localidad?
                                        </Typography>
                                        <Typography variant="subtitle2" onClick={() => toggleAgenciesModalOpen()} className={classes.agencyNotFoundButton}>
                                            Retirar en agencia más cercana
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Container>
            ) : (
                <Box>
                    <Box mt={2} mb={3} className={classes.resume}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={9}>
                                <Typography variant="h6">{`${shippingDataHelper.formatName(shippingData.locality.name)} - ${shippingData.province.name}`}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                {isSubsidizedShippingPrice ? (
                                    <Typography variant="subtitle1" style={{ fontWeight: 700, color: colors.green }}>
                                        ENVÍO GRATIS
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1" className={classes.resumeTypography}>
                                        Envío:{' '}
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                display: 'inline',
                                                textDecoration: isSubsidizedShippingPrice ? 'line-through' : 'none',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {cartHelper.formatPrice(shippingData.price)}
                                        </Typography>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography variant="subtitle2" className={classes.changeLocalityButton} onClick={() => setSeeChooseAddress(true)}>
                        Cambiar Localidad
                    </Typography>
                </Box>
            )}
            <ShippingAgencySelectionModal
                availableProvinces={availableProvinces}
                openAgenciesModal={agenciesModal}
                productId={productId}
                selectedProvince={selectedProvince}
                setDoorToDoorMode={setDoorToDoorMode}
                setShippingData={setShippingData}
                shippingData={shippingData}
                toggleAgenciesModalOpen={toggleAgenciesModalOpen}
                toggleProductShippingQuoteModal={toggleProductShippingQuoteModal}
                initialShippingDataForAgencyChange={initialShippingDataForAgencyChange}
            />
            <Dialog
                open={openFindAgenciesDialog}
                onClose={toggleFindAgenciesDialogOpen}
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Envío puerta a puerta</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Lamentablemente no hacemos envíos puerta a puerta a tu dirección, pero te lo podemos enviar a una agencia cercana
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleFindAgenciesDialogOpen} className={classes.greyButton}>
                        Volver atrás
                    </Button>
                    <Button
                        onClick={() => {
                            toggleAgenciesModalOpen();
                            toggleFindAgenciesDialogOpen();
                        }}
                        autoFocus
                        className={classes.yellowButton}
                    >
                        Seleccionar Agencia
                    </Button>
                </DialogActions>
            </Dialog>
            <Box xs={12} mt={5} className={classes.selectButtonContainer}>
                {seeChooseAddress ? (
                    <LoadingButton onClick={handleCalculateShipping} loading={loadingQuote} className={classes.stepChangeButton} variant="contained">
                        Calcular Envío
                    </LoadingButton>
                ) : (
                    <LoadingButton onClick={handleShippingPricePreview} className={classes.stepChangeButton} variant="contained">
                        Continuar
                    </LoadingButton>
                )}
            </Box>
        </div>
    );
});

const ProductShippingQuoteModal = ({ setShippingData, shippingData, openProductShippingQuoteModal, toggleProductShippingQuoteModal, productId, initialShippingDataForAgencyChange }) => {
    const classes = useStyles();
    return (
        <Modal open={openProductShippingQuoteModal} onClose={() => toggleProductShippingQuoteModal(false)} className={classes.productModal}>
            <ProductShippingQuoteModalBody
                toggleProductShippingQuoteModal={toggleProductShippingQuoteModal}
                shippingData={shippingData}
                setShippingData={setShippingData}
                productId={productId}
                initialShippingDataForAgencyChange={initialShippingDataForAgencyChange}
            />
        </Modal>
    );
};
export default ProductShippingQuoteModal;
