import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ImageRadio from '../../../ImageRadio';
import desktop from '../../images/desktop.svg';
import notebook from '../../images/notebook.svg';

const useStyles = makeStyles(() => ({
    box: {
        maxWidth: 605,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

const HardwareSelect = () => {
    const classes = useStyles();

    return (
        <>
            <Box mb={2}>
                <Typography variant="subtitle1">Próximamente podrás seleccionar también la opción Notebook</Typography>
            </Box>
            <Box className={classes.box} my={4} mx="auto" display="flex">
                <ImageRadio key="desktop" image={desktop} title="PC" checked defaultImage={desktop} />
                <ImageRadio key="notebook" image={notebook} title="Notebook" checked={false} defaultImage={notebook} />
            </Box>
        </>
    );
};

export default HardwareSelect;
