import { Typography } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';

import { useTreeItemStyles } from './styles';

interface Props {
    labelText: string;
    nodeId: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

const StyledTreeItem = ({ labelText, ...other }: Props) => {
    const classes = useTreeItemStyles();

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <Typography variant="body1" className={classes.labelText}>
                        {labelText}
                    </Typography>
                </div>
            }
            classes={{
                content: classes.content,
            }}
            {...other}
        />
    );
};

export default StyledTreeItem;
