import { Box } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';

import API from '../api/API';
import { DEFAULT_CENTER, SHIPPING_ERROR_MESSAGE } from '../common/constants';
import { useConfig } from '../contexts/Config/context';
import { configKeys } from '../contexts/Config/types';
import { UserContext } from '../contexts/User';
import handler from '../helpers/handler';
import shippingDataHelper from '../helpers/shippingDataHelper';
import storageHelper from '../helpers/storageHelper';
import Pin from '../resources/completeOrder/components/google_maps_pin.png';
import useSnackbarGD from './useSnackbar';

const useShippingAgencySelection = ({ initialLocality, initialProvince, productId, setShippingData, shippingData }) => {
    const { showSnackbarMessage } = useSnackbarGD();

    const cart = storageHelper.getUserCart();
    const [, dispatch] = useContext(UserContext);
    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();
    const [availableAgencies, setAvailableAgencies] = useState([]);
    const [center, setCenter] = useState(DEFAULT_CENTER);
    const [matchedAgencies, setMatchedAgencies] = useState([]);
    const [selectedLocality, setSelectedLocality] = useState(initialLocality);
    const [selectedProvince, setSelectedProvince] = useState(initialProvince);
    const [uniqueLocalities, setUniqueLocalities] = useState([]);
    const [zoom, setZoom] = useState(4);

    const resetShippingAgencySelection = () => {
        setAvailableAgencies([]);
        setCenter(DEFAULT_CENTER);
        setMatchedAgencies([]);
        setSelectedLocality({});
        setSelectedProvince('');
        setUniqueLocalities([]);
    };

    const handleChangeProvince = (newProvince) => {
        setSelectedProvince(newProvince);
        setShippingData(
            shippingDataHelper.getShippingDataForProvinceChange({
                newProvince,
                shippingData,
            })
        );
        setMatchedAgencies([]);
    };

    const addAgency = ({ agency, price, agenciesValidForMap }) => {
        let agencyLatitude = agency.lat;
        let agencyLongitude = agency.long;
        if (agencyLatitude === null || agencyLongitude === null) {
            agencyLatitude = agenciesValidForMap[0].lat;
            agencyLongitude = agenciesValidForMap[0].long;
        }
        const newAgency = {
            ...agency,
            lat: agencyLatitude,
            long: agencyLongitude,
            price,
        };
        setMatchedAgencies((oldMatchedAgencies) => [...oldMatchedAgencies, newAgency]);
    };

    const markers = useMemo(() => {
        if (!matchedAgencies.length) return null;

        return matchedAgencies.map((agency) => (
            <Box key={agency.id} lng={parseFloat(agency.long)} lat={parseFloat(agency.lat)} className="marker temporary-marker">
                <span>
                    <img src={Pin} alt="marcador" width="30px" height="52px" />
                </span>
            </Box>
        ));
    }, [matchedAgencies]);

    const onAgencyClick = (agency, isSelected) => {
        const newShippingData = {
            ...shippingData,
            ...(isSelected
                ? {
                      address: agency.address,
                      locality: agency,
                      price: agency.price,
                  }
                : {
                      address: '',
                      locality: null,
                      price: null,
                  }),
        };

        setShippingData(newShippingData);
    };

    const mapRelocationBasedOnAgenciesData = (agenciesValidForMap) => {
        setCenter({
            lat: agenciesValidForMap.length === 0 ? -37.98 : parseFloat(agenciesValidForMap[0].lat),
            lng: agenciesValidForMap.length === 0 ? -63.36 : parseFloat(agenciesValidForMap[0].long),
        });
        setZoom(agenciesValidForMap.length === 0 ? 3 : 14);
    };

    const handleMatchedAgenciesCollapsable = (locality) => {
        if (selectedLocality[locality.id] === true) {
            setSelectedLocality((prevState) => ({ ...prevState, [locality.id]: false }));
            const newMatchedAgencies = matchedAgencies.filter((a) => a.name !== locality.name);
            setMatchedAgencies(newMatchedAgencies);
        } else {
            setSelectedLocality((prevState) => ({ ...prevState, [locality.id]: true }));
        }
    };

    const handleAgenciesDisplay = ({ locality }) => {
        setSelectedLocality((oldSelectedLocality) => {
            if (oldSelectedLocality[locality.id]) {
                return {
                    ...oldSelectedLocality,
                    [locality.id]: !oldSelectedLocality[locality.id],
                };
            }

            return { ...oldSelectedLocality, [locality.id]: 'loading' };
        });

        const newShippingData = {
            ...shippingData,
            address: locality.address,
            codigoPostal: locality.codigoPostal,
            locality,
        };
        setShippingData(newShippingData);

        const filteredAgencies = availableAgencies.filter((agency) => agency.name.toUpperCase() === locality.name.toUpperCase());

        const agenciesValidForMap = filteredAgencies.filter((agency) => agency.lat !== null && agency.long !== null);
        mapRelocationBasedOnAgenciesData(agenciesValidForMap);

        if (selectedLocality) {
            const cartId = cart.id;

            filteredAgencies.forEach((agency) => {
                API.shipping
                    .quote({ productId, cartId, shippingData: newShippingData })
                    .then((response) => {
                        const { price: quotedPrice } = response.data;
                        const price = isSubsidizedShippingPrice ? 0 : quotedPrice;
                        setShippingData({ ...newShippingData, price });
                        addAgency({ agency, price, agenciesValidForMap });
                        handleMatchedAgenciesCollapsable(locality);
                    })
                    .catch((error) => {
                        setSelectedLocality((prevState) => ({ ...prevState, [locality.id]: false }));
                        return handler.handleError({
                            error,
                            userContextDispatch: dispatch,
                            showSnackbarMessage,
                            customErrorMessage: SHIPPING_ERROR_MESSAGE,
                        });
                    });
            });
        }
    };

    const getAvailableAgencies = async () => {
        const agencies = await shippingDataHelper.getAvailableAgencies(selectedProvince);

        setAvailableAgencies(agencies);
    };

    const getUniqueLocalities = async () => {
        const localities = await shippingDataHelper.getUniqueLocalities(availableAgencies);

        setUniqueLocalities(localities);
    };

    useEffect(() => {
        if (selectedProvince) getAvailableAgencies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProvince]);

    useEffect(() => {
        if (availableAgencies?.length) getUniqueLocalities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableAgencies]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setCenter({
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
            });
            setZoom(13);
        });
    }, []);

    return {
        center,
        markers,
        matchedAgencies,
        selectedLocality,
        selectedProvince,
        uniqueLocalities,
        zoom,
        handleAgenciesDisplay,
        handleChangeProvince,
        onAgencyClick,
        resetShippingAgencySelection,
    };
};

export default useShippingAgencySelection;
