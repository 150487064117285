import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

export const cablingOptions: KeyValue[] = [
    { key: 'fijo', value: 'Fijo' },
    { key: 'modular', value: 'Modular' },
];

export const certification80PlusOptions: KeyValue[] = [
    { key: 'bronze', value: 'Bronze' },
    { key: 'gold', value: 'Gold' },
    { key: 'platinum', value: 'Platinum' },
    { key: 'titanium', value: 'Titanium' },
];

export const formats: KeyValue[] = [
    { key: 'ATX', value: 'ATX' },
    { key: 'SFX', value: 'SFX' },
];

export default {
    cabling: cablingOptions,
    certification80Plus: certification80PlusOptions,
    format: formats,
    lighting: boolean,
};
