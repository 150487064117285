import { Action, ConfigState, TypeAction } from './types';

const configReducer = (state: ConfigState, action: Action): ConfigState => {
    switch (action.type) {
        case TypeAction.FETCH_CONFIGS_SUCCESS:
            return {
                ...state,
                ...action.config,
            };
        case TypeAction.FETCH_CONFIGS_ERROR:
        default:
            return state;
    }
};

export default configReducer;
