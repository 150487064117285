import { useSnackbar } from 'notistack';
import { CSSProperties } from 'react';

const commonStyles: CSSProperties = {
    borderRadius: 0,
};

export type SelectedVariantType = 'success' | 'warning' | 'error';

const variantStyles: Record<SelectedVariantType, CSSProperties> = {
    success: {
        color: 'white',
        backgroundColor: '#4CB963',
    },
    warning: {
        color: '#F8E837',
        backgroundColor: '#1C1C1C',
    },
    error: {
        color: 'white',
        backgroundColor: '#EA4335',
    },
};

const getStyles = (variant: SelectedVariantType): CSSProperties => ({
    ...commonStyles,
    ...variantStyles[variant],
});

const useSnackbarGD = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSnackbarMessage = (message: string, variant: SelectedVariantType) => {
        enqueueSnackbar(message, {
            variant,
            hideIconVariant: true,
            style: getStyles(variant),
        });
    };
    return {
        showSnackbarMessage,
    };
};

export default useSnackbarGD;
