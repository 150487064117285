import { Box, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { productTypesDefinitions } from '../../../helpers/types';
import { ProductType } from '../../../types/ProductType';
import { getTotalResultsText } from '../utils';

interface Props {
    onProductTypeUnselected: () => void;
    productType: ProductType;
    totalResults?: number;
}
const SelectedProductType = ({ productType, totalResults, onProductTypeUnselected }: Props) => {
    const productTypeConfig = productTypesDefinitions[productType];

    const title = productTypeConfig.title;
    const icon = productTypeConfig.icon;

    return (
        <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" width="auto">
                <img
                    src={icon}
                    alt={title}
                    style={{
                        width: '51px',
                        height: '46px',
                    }}
                />
                <Box display={'flex'} flexDirection={'column'} ml={1}>
                    <Typography variant="h1" style={{ fontSize: '18px', lineHeight: '22px' }}>
                        {title}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" style={{ fontSize: 12 }}>
                        {getTotalResultsText(totalResults)}
                    </Typography>
                </Box>

                <IconButton
                    style={{
                        marginRight: '10px',
                        height: '30px',
                        width: '30px',
                        marginLeft: '10px',
                    }}
                    onClick={onProductTypeUnselected}
                >
                    <ClearIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default SelectedProductType;
