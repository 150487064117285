import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from '@material-ui/lab/Skeleton';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import API from '../../../api/API';
import { ProductsContext } from '../../../contexts/Products/context';
import { UserContext, useUser } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import { helmetCheckoutConfig } from '../../../data/seo';
import cartHelper from '../../../helpers/cartHelper';
import { sendPageViewGAEvent, sendRemoveFromCartGAEvent } from '../../../helpers/gaHelper';
import handler from '../../../helpers/handler';
import useSnackbarGD from '../../../hooks/useSnackbar';
import GDTheme from '../../../theme/GDTheme';
import PageContent from '../../components/PageContent';
import Items from './Items';
import Resume from './Resume';

const useStyles = makeStyles(() => ({
    actionButtons: {
        marginBottom: 22,
        display: 'flex',
        justifyContent: 'center',
    },
    checkoutFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 769px)': {
            flexDirection: 'column',
        },
    },
    resumeContainer: {
        '@media (max-width: 769px)': {
            flexGrow: 1,
            marginTop: GDTheme().spacing(2),
        },
    },
}));

const Checkout = () => {
    const classes = useStyles();
    const [openClearCartDialog, setOpenClearCartDialog] = React.useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [, setCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const { productsDispatch } = useContext(ProductsContext);
    const { showSnackbarMessage } = useSnackbarGD();
    const {
        cart: { clear },
    } = useUser();

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        if (state.user?.cart.id && state.user?.cart.items) {
            setCart(state.user.cart);
        } else {
            setLoading(true);
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                    setLoading(false);
                })
                .catch((error) => {
                    handler.handleError({
                        error,
                        userContextDispatch: dispatch,
                        showSnackbarMessage,
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch]);

    const handleUpdateCart = (updatedCart) => {
        const cartToSend = updatedCart;
        cartToSend.items = cartHelper.parseProductsToSend(cartToSend.items);
        setLoading(true);
        API.carts
            .updateCart(cartToSend)
            .then((response) => {
                cartHelper.warnIfCartHasChanged(response.data.items);
                dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                showSnackbarMessage('Se actualizó la cantidad del producto.', 'success');
            })
            .catch((error) => {
                const customErrorMessage = 'Hubo un problema al actualizar el carrito. Por favor intente nuevamente en unos minutos';
                handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                    customErrorMessage,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleClickOpenClearCartDialog = () => {
        setOpenClearCartDialog(true);
    };

    const handleCloseClearCartDialog = () => {
        setOpenClearCartDialog(false);
    };

    const removeAssociatedBuildSOFromCartIfExists = (newCart, buildToDelete) => {
        const { buildSubItemIndex } = buildToDelete;
        let deletedSO = null;
        newCart.items.forEach((item, index) => {
            if (buildSubItemIndex === item.buildId) {
                // FIXME: Estamos haceindo un splice dentro de un forEach, puede ser problematico.
                [deletedSO] = newCart.items.splice(index, 1);
            }
        });
        return deletedSO;
    };

    const deleteItem = (itemId, buildId) => {
        const newCart = state.user.cart;
        let itemsToSend = newCart.items;
        const deletedItems = [];
        if (buildId) {
            productsDispatch({
                type: 'DELETE_BUILD',
            });
            itemsToSend = newCart.items.filter((product) => product.buildId !== buildId);
            // FIXME: We're missing to remove the SO associated to the build from the cart
            const deletedItem = newCart.items.find((product) => product.buildId === buildId);
            if (deletedItem) deletedItems.push(deletedItem);
        } else {
            const itemToDeleteIndex = newCart.items.findIndex((item) => item.id === itemId);
            newCart.items.every((item) => {
                if (itemId === item.id) {
                    deletedItems.push(item);
                    // TODO: Revisar esto. Me parece que estamos preguntando por "type" y ese campo
                    // no tiene el tipo de producto, sino el tipo de item de carrito.
                    if (item.type === 'build') {
                        const deletedSO = removeAssociatedBuildSOFromCartIfExists(newCart, item);
                        if (deletedSO) deletedItems.push(deletedSO);
                    }
                    // FIXME: Estamos haciendo un splice, y despues otro, sin buscar de nuevo
                    // el indice. eso puede ser problematico porque pudo haber cambiado
                    newCart.items.splice(itemToDeleteIndex, 1);
                    return false;
                }
                return true;
            });
        }
        sendRemoveFromCartGAEvent(cartHelper.formatCartItemsForGAEvent(deletedItems, true));
        newCart.items = cartHelper.parseProductsToSend(itemsToSend);
        setLoading(true);
        API.carts
            .updateCart(newCart)
            .then((response) => {
                dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                showSnackbarMessage('Producto eliminado del carrito.', 'success');
            })
            .catch((error) => {
                const customErrorMessage = 'Hubo un problema al eliminar el producto del carrito. Por favor intente nuevamente en unos minutos';
                handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                    customErrorMessage,
                });
            })
            .finally(() => setLoading(false));
    };

    const clearCart = () => {
        setLoading(true);
        clear().finally(() => setLoading(false));
        setOpenClearCartDialog(false);
    };

    return (
        <PageContent breadcrumb={[{ name: 'Carrito' }]}>
            <Helmet>
                <title>{helmetCheckoutConfig.title}</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content={helmetCheckoutConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetCheckoutConfig.title} />
                <meta property="og:description" content={helmetCheckoutConfig.description} />
            </Helmet>
            <Box className={classes.checkoutFlexContainer}>
                <Box flexGrow={1}>
                    <Box mb={4}>
                        <Typography variant="h1" style={{ fontSize: '20px', lineHeight: '24px' }}>
                            Descripción de tu pedido
                        </Typography>
                    </Box>
                    {loading ? (
                        <>
                            <Skeleton variant="rect" width="90%" height="90%" />
                        </>
                    ) : (
                        <Items updateCart={handleUpdateCart} deleteItem={deleteItem} />
                    )}
                </Box>
                <Box className={classes.resumeContainer}>
                    <Resume clearCart={handleClickOpenClearCartDialog} />
                </Box>
            </Box>
            <Dialog open={openClearCartDialog} onClose={handleCloseClearCartDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                    <Button onClick={handleCloseClearCartDialog} className={classes.closeIcon}>
                        <CloseIcon />
                    </Button>
                </Box>
                <DialogTitle id="form-dialog-title">
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6">Limpiar carrito</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h6" style={{ fontWeight: 300 }}>
                                ¿Querés eliminar todos los productos del carrito?
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actionButtons}>
                    <FormControl>
                        <Button variant="outlined" onClick={handleCloseClearCartDialog}>
                            Cancelar
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button variant="contained" onClick={clearCart}>
                            Eliminar
                        </Button>
                    </FormControl>
                </DialogActions>
            </Dialog>
        </PageContent>
    );
};
export default Checkout;
