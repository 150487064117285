import { Button, CircularProgress, Paper, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../api/API';
import { UserContext } from '../../contexts/User';
import userConstants from '../../contexts/User/userConstants';
import handler from '../../helpers/handler';
import imageHelper from '../../helpers/imageHelper';
import useSnackbarGD from '../../hooks/useSnackbar';
import emptyUser from './emptyUser.svg';

const useStyles = makeStyles((theme) => ({
    userProfile: {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundSize: 'cover',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: 85,
            height: 85,
        },
        [theme.breakpoints.up('sm')]: {
            width: 128,
            height: 128,
        },
    },
    textProfile: {
        backgroundColor: 'rgba(28, 28, 28, 0.5)',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            width: 85,
        },
        [theme.breakpoints.up('sm')]: {
            width: 128,
        },
    },
}));

const UserPhotoEdit = ({ userPicture }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const [, dispatch] = useContext(UserContext);
    const { showSnackbarMessage } = useSnackbarGD();
    const [fileUrl, setFileUrl] = useState(emptyUser);
    const [editing, setEditing] = useState(false);

    const handleFileInputChange = (e) =>
        new Promise(() => {
            setEditing(true);
            imageHelper
                .convertPhotoToBase64(e.target.files[0])
                .then((result) => API.users.patchMe({ image: result }))
                .then((response) => {
                    const newFileURL = response.data.user.profileImageURL;
                    showSnackbarMessage('Su foto de perfil ha sido actulizada correctamente', 'success');
                    setFileUrl(newFileURL);
                    dispatch({
                        type: userConstants.UPDATE_PROFILE_PICTURE,
                        profileImageURL: newFileURL,
                    });
                })
                .catch((err) => {
                    handler.handleError({
                        error: err,
                        userContextDispatch: dispatch,
                        showSnackbarMessage,
                        navigate,
                        redirectToHome: true,
                    });
                })
                .finally(() => setEditing(false));
        });
    useEffect(() => {
        if (userPicture) setFileUrl(userPicture);
        else setFileUrl(emptyUser);
    }, [userPicture]);

    return (
        <Paper square className={classes.userProfile} style={{ backgroundImage: `url(${fileUrl})` }}>
            <Button className={classes.textProfile} onClick={() => inputFile.current.click()}>
                {editing ? (
                    <>
                        Editando <CircularProgress color="primary" size={16} />
                    </>
                ) : (
                    'Editar'
                )}
            </Button>
            <input type="file" name="imagen" accept="image/*" ref={inputFile} onChange={handleFileInputChange} style={{ display: 'none' }} />
        </Paper>
    );
};

export default UserPhotoEdit;
