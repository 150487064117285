import { KeyValue } from '../../types/KeyValue';

const widths: KeyValue[] = [
    { key: 120, value: '120mm' },
    { key: 140, value: '140mm' },
];

const connections: KeyValue[] = [
    { key: '3-pin', value: '3-Pin' },
    { key: '4-pin', value: '4-Pin' },
];

const lightings: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'fixedColor', value: 'Color Fijo' },
    { key: 'none', value: 'Sin iluminación' },
];

export default {
    width: widths,
    connection: connections,
    lighting: lightings,
};
