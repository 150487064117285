import { Box, Button, Hidden, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import API from '../../../api/API';
import { ProductsContext } from '../../../contexts/Products/context';
import { UserContext } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import { helmetProBuilderConfig } from '../../../data/seo';
import cartHelper from '../../../helpers/cartHelper';
import { sendAddToCartGAEvent, sendPageViewGAEvent } from '../../../helpers/gaHelper';
import handler from '../../../helpers/handler';
import proBuildHelper from '../../../helpers/proBuildHelper';
import { ProductTypes } from '../../../helpers/types';
import useSnackbarGD from '../../../hooks/useSnackbar';
import caseIcon from '../../../productImages/icons/case.svg';
import coolerIcon from '../../../productImages/icons/cooler.svg';
import cpuIcon from '../../../productImages/icons/cpu.svg';
import gpuIcon from '../../../productImages/icons/gpu.svg';
import monitor from '../../../productImages/icons/monitor.svg';
import motherIcon from '../../../productImages/icons/mother.svg';
import powerIcon from '../../../productImages/icons/power.svg';
import ramIcon from '../../../productImages/icons/ram.svg';
import storageIcon from '../../../productImages/icons/storage.svg';
import PageContent from '../../components/PageContent';
import ProBuilderHeader from './ProBuilderHeader';
import Roadmap from './Roadmap';
import CPUBrandSelect from './steps/CPUBrandSelect';
import CPUSelect from './steps/CPUSelect';
import CaseFanSelect from './steps/CaseFanSelect';
import CaseSelect from './steps/CaseSelect';
import CoolerSelect from './steps/CoolerSelect';
import GPUSelect from './steps/GPUSelect';
import MotherSelect from './steps/MotherSelect';
import PeripheralsSelect from './steps/PeripheralsSelect';
import PowerSelect from './steps/PowerSelect';
import RAMSelect from './steps/RAMSelect';
import StorageSelect from './steps/StorageSelect';

const useStyles = makeStyles(() => ({
    container: {
        '@media (max-width: 960px)': {
            flexDirection: 'column',
        },
    },
    yellowButton: {
        backgroundColor: '#F8E837!important',
        color: '#1C1C1C!important',
    },
    greyButton: {
        backgroundColor: '#DBDBD5!important',
        color: '#1C1C1C!important',
    },
}));

const ProBuilder = () => {
    const classes = useStyles();
    const [actualStep, setActualStep] = useState(0);
    const [goToNextAllowed, setGoToNextAllowed] = useState(false);
    const [showComponents, setShowComponents] = useState(true);
    const { productsProBuilder, productsDispatch } = useContext(ProductsContext);
    const [evenRams, setEvenRams] = useState(false);
    const [selectedGPU, setSelectedGPU] = useState(false);
    const [openMissingGPUDialog, setOpenMissingGPUDialog] = useState(false);
    const [openEvenRamDialog, setOpenEvenRamDialog] = useState(false);
    const [proBuildInCartDialog, setProBuildInCartDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const myRef = useRef(null);
    const [state, dispatchUser] = useContext(UserContext);
    const { showSnackbarMessage } = useSnackbarGD();
    const navigate = useNavigate();
    const params = useParams();

    const handleCloseEvenRamDialog = ({ reason }) => {
        if (reason && reason === 'backdropClick') return;
        setOpenEvenRamDialog(false);
    };

    const handleCloseMissingGPUDialog = ({ reason }) => {
        if (reason && reason === 'backdropClick') return;
        setOpenMissingGPUDialog(false);
    };

    const handleCloseProBuildInCartDialog = ({ reason }) => {
        if (reason && reason === 'backdropClick') return;
        setProBuildInCartDialog(false);
    };

    const handleClickCreateNewBuild = (e) => {
        handleCloseProBuildInCartDialog(e);
        productsDispatch({
            type: 'DELETE_BUILD',
        });
    };

    const handleGoToCart = () => {
        navigate('/checkout');
    };

    const handleShowComponents = () => {
        setShowComponents(!showComponents);
    };

    const updateBuildSpecs = (newSpecs) => {
        const productContent = Object.values(newSpecs)[0];
        const key = Object.keys(newSpecs)[0];
        productsDispatch({
            type: 'MODIFY_BUILD',
            product: {
                key,
                productContent,
            },
        });
    };

    const steps = [
        {
            name: 'cpuBrand',
            title: 'Brand',
            description: (
                <Typography align="left" variant="subtitle1">
                    Elegi la marca de tu procesador y desbloquea un mundo de motherboards compatibles.
                </Typography>
            ),
            icon: cpuIcon,
            content: <CPUBrandSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.MOTHER,
            title: 'Motherboard',
            description: (
                <Typography align="left" variant="subtitle1">
                    El motherboard es el circuito principal de la computadora.
                </Typography>
            ),
            icon: motherIcon,
            content: <MotherSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.CPU,
            title: 'CPU',
            description: (
                <Typography align="left" variant="subtitle1">
                    La CPU es el cerebro de tu computadora.
                </Typography>
            ),
            icon: cpuIcon,
            content: <CPUSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.CPU_COOLER,
            title: 'Cooler',
            description: productsProBuilder.cpu?.specs?.has_cooler ? (
                <Typography align="left" variant="subtitle1">
                    Ya tenés un cooler seleccionado y sin costo adicional, ya que la CPU que <br />
                    elegiste tiene uno incluído. Podés continuar con este o elegir uno nuevo.
                </Typography>
            ) : (
                <Typography align="left" variant="subtitle1">
                    El cooler tiene como función principal disminuir la temperatura de los componentes internos de tu computadora para evitar el sobrecalentamiento.
                </Typography>
            ),
            icon: coolerIcon,
            content: <CoolerSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.RAM,
            title: 'RAM',
            description: (
                <Typography align="left" variant="subtitle1">
                    Facilita el acceso rápido a los datos para una ejecución eficiente de los juegos y aplicaciones.
                </Typography>
            ),
            icon: ramIcon,
            content: <RAMSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} setEvenRams={setEvenRams} />,
        },
        {
            name: ProductTypes.GPU,
            title: 'GPU',
            description: (
                <Typography align="left" variant="subtitle1">
                    La GPU está diseñada para aligerar la carga de trabajo de la CPU respecto a videojuegos, edición de video, diseño gráfico.
                </Typography>
            ),
            icon: gpuIcon,
            content: <GPUSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} setSelectedGPU={setSelectedGPU} />,
        },
        {
            name: ProductTypes.STORAGE,
            title: 'Almacenamiento',
            description: (
                <Typography align="left" variant="subtitle1">
                    Guarda todos los datos que los programas necesitan para ejecutar tareas.
                </Typography>
            ),
            icon: storageIcon,
            content: <StorageSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.CASE,
            title: 'Gabinete',
            description: (
                <Typography align="left" variant="subtitle1">
                    Donde se alojan todos los componentes internos de tu PC
                </Typography>
            ),
            icon: caseIcon,
            content: <CaseSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.POWER,
            title: 'Fuente',
            description: (
                <Typography align="left" variant="subtitle1">
                    Es el corazón de tu computadora gamer. Es la responsable de convertir la corriente y estabilizarla para alimentar y cuidar todos los componentes.
                </Typography>
            ),
            icon: powerIcon,
            content: <PowerSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: ProductTypes.CASE_FAN,
            title: 'Fans',
            description: (
                <>
                    <Typography align="left" variant="subtitle1">
                        Ayuda a mantener los componentes frescos para evitar el sobrecalentamiento y el mal funcionamiento.
                    </Typography>
                    <Typography align="left" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Al armar la PC validaremos la posibilidad de colocar los fans que selecciones de acuerdo al Gabinete. ¡Cualquier inconveniente nos comunicaremos con vos!
                    </Typography>
                </>
            ),
            icon: coolerIcon,
            content: <CaseFanSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
        {
            name: 'peripherals',
            title: '¿Querés agregar periféricos a tu PC?',
            description: (
                <Typography align="left" variant="subtitle1">
                    Te mostramos los complementos recomendados para vos, podés agregarlos o finalizar el armado de tu PC y realizar la compra.
                </Typography>
            ),
            icon: monitor,
            content: <PeripheralsSelect updateBuildSpecs={updateBuildSpecs} buildSpecs={productsProBuilder} setGoToNextAllowed={setGoToNextAllowed} />,
        },
    ];
    const isLastStep = actualStep === steps.length - 1;
    const isFirstStep = actualStep === 0;

    const formatProductsFromProBuildForGA = (buildId) =>
        Object.entries(productsProBuilder)
            .filter(([key, value]) => !['id', 'cpuBrand'].includes(key) && value)
            .map(([, value]) => value)
            .flat()
            .map((value) => ({
                item_id: value.id,
                item_name: value.name,
                item_brand: value.brand,
                quantity: value.quantity,
                price: value.price.special.ARS,
                for_probuild_id: buildId,
            }));

    const formatProductsFromProBuild = (buildId) => {
        const build = [];
        const doNotAddTheseItems = ['cpuBrand', 'peripherals', 'id'];
        Object.keys(productsProBuilder).forEach((key) => {
            if (productsProBuilder[key]) {
                if (proBuildHelper.multiSelectSteps.includes(key)) {
                    productsProBuilder[key].forEach((element) => {
                        const proBuilderItem = {
                            id: element.id,
                            quantity: element.quantity || 1,
                            buildId,
                        };
                        build.push(proBuilderItem);
                    });
                } else if (!doNotAddTheseItems.includes(key)) {
                    const proBuilderItem = {
                        id: productsProBuilder[key].id,
                        quantity: productsProBuilder[key].quantity || 1,
                        buildId,
                    };
                    build.push(proBuilderItem);
                }
            }
        });
        return build;
    };

    const getItemsToKeepInCartWhenPersonalizing = (currentCart) => {
        let itemsToKeep = [];
        const easyBuildIndex = currentCart.items.findIndex((product) => product.name === params.fromPersonalize);
        if (easyBuildIndex !== -1) {
            currentCart.items.splice(easyBuildIndex, 1);
            itemsToKeep = currentCart.items;
        } else {
            itemsToKeep = currentCart.items.filter((product) => product.buildId !== params.fromPersonalize);
        }
        return itemsToKeep;
    };

    const updateCart = (cartToSend) => {
        API.carts
            .updateCart(cartToSend)
            .then(async (response) => {
                cartHelper.warnIfCartHasChanged(response.data.items);
                dispatchUser({ type: userConstants.UPDATE_CART, cart: response.data });
                handleGoToCart();
            })
            .catch((error) => {
                handler.handleError({ error, userContextDispatch: dispatchUser, showSnackbarMessage });
            });
    };

    const handleAddToCart = async () => {
        if (!state.user?.cart.id) {
            try {
                const response = await API.carts.post();
                dispatchUser({ type: userConstants.UPDATE_CART, cart: response.data });
            } catch (error) {
                handler.handleError({ error, userContextDispatch: dispatchUser, showSnackbarMessage });
            }
        }
        const currentCart = state.user.cart;
        const buildId = uuidV4();
        sendAddToCartGAEvent(formatProductsFromProBuildForGA(buildId));
        const formattedProBuildProducts = formatProductsFromProBuild(buildId);

        let currentItems = [];
        if (params.fromPersonalize) {
            currentItems = getItemsToKeepInCartWhenPersonalizing(currentCart);
        } else {
            currentItems = currentCart.items.filter((cartItem) => cartItem.id !== buildId && cartItem.buildId !== buildId);
        }

        const cartItemsToSend = [];
        cartItemsToSend.push(...(currentItems || []));
        cartItemsToSend.push(...formattedProBuildProducts);

        if (productsProBuilder.peripherals && productsProBuilder.peripherals.length > 0) {
            productsProBuilder.peripherals.forEach((periphereal) => {
                const peripherealIndexInCart = cartItemsToSend.findIndex((cartItem) => cartItem.id === periphereal.id);
                if (peripherealIndexInCart === -1) {
                    const peripheralProduct = {
                        id: periphereal.id,
                        quantity: periphereal.quantity || 1,
                    };
                    cartItemsToSend.push(peripheralProduct);
                } else {
                    cartItemsToSend[peripherealIndexInCart].quantity += periphereal.quantity || 1;
                }
            });
        }

        const parsedCartItemsToSend = cartHelper.parseProductsToSend(cartItemsToSend);
        const cartToSend = { ...currentCart, items: [...parsedCartItemsToSend] };

        updateCart(cartToSend);
    };

    const goToNextStep = () => {
        if (!isLastStep) {
            if (productsProBuilder.ram && actualStep === 4 && !evenRams && !openEvenRamDialog) {
                return setOpenEvenRamDialog(true);
            }

            if (!productsProBuilder.gpu && actualStep === 5 && !selectedGPU && !openMissingGPUDialog) {
                return setOpenMissingGPUDialog(true);
            }
            setActualStep(actualStep + 1);
            setGoToNextAllowed(false);
            setOpenEvenRamDialog(false);
            setOpenMissingGPUDialog(false);
            return null;
        }
        setIsSubmitting(true);
        return handleAddToCart();
    };

    const goToPreviousStep = () => {
        setActualStep(actualStep - 1);
    };

    const isMandatory = (stepName) => {
        const notMandatorySteps = ['id', ProductTypes.CASE_FAN, ProductTypes.CPU_COOLER, ProductTypes.GPU];
        return !notMandatorySteps.includes(stepName);
    };

    const hasAddedProducts = (stepName) => {
        if (proBuildHelper.multiSelectSteps.includes(stepName)) {
            return productsProBuilder[stepName] && productsProBuilder[stepName].length > 0;
        }
        return !!productsProBuilder[stepName];
    };

    const validateStepsOnChange = ({ selectedStep, fromPersonalize }) => {
        if (selectedStep === 0) {
            setActualStep(0);
            return;
        }

        let firstMandatoryStepWithoutProducts;
        const stepsToSlice = selectedStep || 10;

        steps.slice(0, stepsToSlice).every((step, index) => {
            if (isMandatory(step.name) && !hasAddedProducts(step.name)) {
                firstMandatoryStepWithoutProducts = index;
                if (productsProBuilder[steps[index + 1].name] && fromPersonalize) {
                    handler.handleError({
                        showSnackbarMessage,
                        customErrorMessage: 'No se encontró stock del producto seleccionado. Por favor, selecciona otro.',
                    });
                }
                return false;
            }
            return true;
        });
        if (firstMandatoryStepWithoutProducts >= 0) {
            setActualStep(firstMandatoryStepWithoutProducts);
        } else setActualStep(selectedStep || steps.length - 1);
    };

    const handleClick = (selectedStep) => {
        validateStepsOnChange({ selectedStep });
    };

    const bodyProBuilder = (
        <>
            <Hidden smDown>
                <Roadmap
                    handleShowComponents={handleShowComponents}
                    showComponents={showComponents}
                    buildSpecs={productsProBuilder}
                    actualStep={actualStep === 10 ? actualStep - 1 : actualStep}
                    setActualStep={setActualStep}
                    steps={steps}
                    handleClick={handleClick}
                    mobile={false}
                    lastStep={isLastStep}
                />
            </Hidden>
            <Hidden mdUp>
                <Roadmap
                    handleShowComponents={handleShowComponents}
                    showComponents={showComponents}
                    buildSpecs={productsProBuilder}
                    actualStep={actualStep === 10 ? actualStep - 1 : actualStep}
                    setActualStep={setActualStep}
                    steps={steps}
                    handleClick={handleClick}
                    mobile
                    myRef={myRef}
                />
            </Hidden>
            <Box flexGrow={1}>
                <Box>
                    <ProBuilderHeader
                        title={steps[`${actualStep}`].title}
                        icon={steps[`${actualStep}`].icon}
                        description={steps[`${actualStep}`].description}
                        nextStepDisabled={isLastStep}
                        lastStepDisabled={isFirstStep}
                        goToNextAllowed={goToNextAllowed}
                        goToNextStep={goToNextStep}
                        goToPreviousStep={goToPreviousStep}
                        lastStep={isLastStep}
                        isSubmitting={isSubmitting}
                    />
                </Box>
                {steps[`${actualStep}`].content}
            </Box>
        </>
    );
    const proBuildInCart = () => {
        const foundProBuild = state.user && state.user.cart ? state.user.cart.items?.find((element) => element.buildId) : false;
        return foundProBuild;
    };

    useEffect(() => {
        if (productsProBuilder.cpuBrand === null) {
            setActualStep(0);
        }
    }, [productsProBuilder]);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        validateStepsOnChange({ fromPersonalize: params.fromPersonalize });
        if (!params.fromPersonalize) {
            setProBuildInCartDialog(proBuildInCart());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContent breadcrumb="Pro Builder">
            <Helmet>
                <title>{helmetProBuilderConfig.title}</title>
                <meta name="description" content={helmetProBuilderConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetProBuilderConfig.title} />
                <meta property="og:description" content={helmetProBuilderConfig.description} />
            </Helmet>
            <Box display="flex" align="center" className={classes.container}>
                {bodyProBuilder}
            </Box>
            <Dialog open={openEvenRamDialog} onClose={handleCloseEvenRamDialog} disableEscapeKeyDown aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">¿Querés seleccionar una cantidad par de rams?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Te sugerimos seleccionar una cantidad par para aprovechar la capacidad del mother.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goToNextStep} className={classes.greyButton}>
                        Continuar al próximo paso
                    </Button>
                    <Button onClick={handleCloseEvenRamDialog} autoFocus className={classes.yellowButton}>
                        Seguir seleccionando
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openMissingGPUDialog}
                onClose={handleCloseMissingGPUDialog}
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-gpu-title"
                aria-describedby="alert-dialog-gpu-description"
            >
                <DialogTitle id="alert-dialog-gpu-title">¿Está seguro que quiere continuar?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-gpu-description">
                        Si no agrega una GPU, su PC no funcionará. <br />
                        {!productsProBuilder.cpu?.specs.igpu &&
                            productsProBuilder.mother?.specs.video_output &&
                            'El CPU que elegiste no posee capacidad de video, asegurate de elegir una placa de video para tu build.'}
                        {!productsProBuilder.mother?.specs.video_output &&
                            productsProBuilder.cpu?.specs.igpu &&
                            'El motherboard que elegiste no tiene salida de video, asegurate de elegir una placa de video para tu build.'}
                        {!productsProBuilder.mother?.specs.video_output &&
                            !productsProBuilder.cpu?.specs.igpu &&
                            'El motherboard que elegiste no tiene salida de video, asegurate de elegir una placa de video para tu build.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goToNextStep} className={classes.greyButton}>
                        Continuar al próximo paso
                    </Button>
                    <Button onClick={handleCloseMissingGPUDialog} autoFocus className={classes.yellowButton}>
                        Seleccionar una GPU
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={proBuildInCartDialog}
                onClose={handleCloseProBuildInCartDialog}
                aria-labelledby="alert-dialog-title"
                disableEscapeKeyDown
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">¡Ya tenemos tu build preparado en el carrito!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">¿Querés crear otro más?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGoToCart} className={classes.yellowButton}>
                        Ir al carrito
                    </Button>
                    <Button onClick={handleClickCreateNewBuild} className={classes.greyButton}>
                        Crear nuevo build
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContent>
    );
};
export default ProBuilder;
