import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useContext } from 'react';

import { useConfig } from '../../../../../contexts/Config/context';
import { configKeys } from '../../../../../contexts/Config/types';
import { UserContext } from '../../../../../contexts/User';
import cartHelper from '../../../../../helpers/cartHelper';
import paymentHelper from '../../../../../helpers/paymentHelper';
import useSnackbarGD from '../../../../../hooks/useSnackbar';
import GDTheme from '../../../../../theme/GDTheme';
import colors from '../../../../../theme/colors';

const useStyles = makeStyles((theme) => ({
    transferDataContainer: {
        background: colors.grey,
        [theme.breakpoints.up('md')]: {
            width: 543,
        },
        padding: 20,
    },
    transferDataTitle: { letterSpacing: 0, marginBottom: 6 },
    transferDataSubTitle: { fontWeight: 700 },
    transferDataText: { fontWeight: 400, marginBottom: 12 },
    cvuCopyIcon: {
        marginLeft: 8,
        height: 14,
        width: 18,
    },
    clickable: { cursor: 'pointer' },

    stepChangeButton: {
        width: 129,
        height: 34,
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
    confirmPaymentButton: {
        width: 164,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

const BankTransferPaymentSubStep = ({ goToSelectPaymentSubStep, selectedPaymentMode, generateOrder, shippingCost }) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);
    const { showSnackbarMessage } = useSnackbarGD();
    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();
    const CVU = '0000003100009006015225';
    const ALIAS = 'GamerFactory.MP';
    const COMPANY_NAME = 'GAMER FACTORY S.R.L';
    const BANK = 'Mercado Pago';
    const CUIT = '30-71722200-4';

    const handleClick = () => {
        const paymentData = {
            amount: cartHelper.getTotalPrice({ cart: state.user?.cart, paymentMode: paymentHelper.paymentModeConstants.BANK_TRANSFER, shippingCost, isSubsidizedShippingPrice }).toString(),
            method: selectedPaymentMode,
        };
        generateOrder(paymentData);
    };

    return (
        <>
            <Grid container className={classes.transferDataContainer}>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.transferDataTitle}>
                        Datos de la cuenta:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} pb={2}>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        Titular
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {COMPANY_NAME}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        CUIT/CUIL
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {CUIT}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        CVU
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        className={`${classes.transferDataText} ${classes.clickable}`}
                        onClick={() => {
                            navigator.clipboard.writeText(CVU);
                            showSnackbarMessage('Texto copiado al portapapeles', 'success');
                        }}
                    >
                        {CVU}
                        <FileCopyOutlinedIcon className={classes.cvuCopyIcon} />
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        Alias
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataText}>
                        {ALIAS}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.transferDataSubTitle}>
                        {BANK}
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.actionButtons} flexGrow={1} m={2} mt={5}>
                <Button variant="outlined" className={classes.stepChangeButton} onClick={goToSelectPaymentSubStep}>
                    Anterior
                </Button>
                <Button variant="contained" className={`${classes.confirmPaymentButton} ${classes.stepChangeButton}`} onClick={handleClick}>
                    Finalizar Compra
                </Button>
            </Box>
        </>
    );
};

export default BankTransferPaymentSubStep;
