import { KeyValue } from '../../types/KeyValue';
import { ProductType } from '../../types/ProductType';
import { CaseFanSlotWidths, RamStickTypes } from '../types';
import boolean from './boolean';
import caseChoices from './caseChoices';
import caseFanChoices from './caseFanChoices';
import chairChoices from './chairChoices';
import cpuChoices from './cpuChoices';
import cpuCoolerChoices from './cpuCoolerChoices';
import gpuChoices from './gpuChoices';
import headsetChoices from './headsetChoices';
import keyboardChoices from './keyboardChoices';
import monitorChoices from './monitorChoices';
import motherChoices from './motherChoices';
import mouseChoices from './mouseChoices';
import powerChoices from './powerChoices';
import ramChoices from './ramChoices';
import storageChoices from './storageChoices';

const ramStickTypes: KeyValue[] = [
    { key: RamStickTypes.DDR_3, value: 'DDR3' },
    { key: RamStickTypes.DDR_4, value: 'DDR4' },
    { key: RamStickTypes.DDR_5, value: 'DDR5' },
];

const radiatorWidth: KeyValue[] = [
    { key: '240', value: '240mm' },
    { key: '360', value: '360mm' },
];

const coolerWidths: KeyValue[] = [
    { key: CaseFanSlotWidths.W_120, value: '120mm' },
    { key: CaseFanSlotWidths.W_140, value: '140mm' },
    { key: CaseFanSlotWidths.W_240, value: '240mm' },
    { key: CaseFanSlotWidths.W_280, value: '280mm' },
    { key: CaseFanSlotWidths.W_360, value: '360mm' },
];

export const genericSpecChoices = {
    brand: cpuChoices.brand,
    cpu_brand: cpuChoices.brand,
    case_radiator_width: coolerWidths,
    cpu_cooler: boolean,
    has_cooler: boolean,
    video_output: boolean,
    igpu: boolean,
    mother_form_factor: motherChoices.formFactors,
    radiator_width: radiatorWidth,
    ram_stick_type: ramStickTypes,
    stick_type: ramStickTypes,
    cpu_socket: cpuChoices.socket,
    socket: cpuChoices.socket,
    sockets: cpuChoices.socket,
};

export const specChoicesForProductType: Record<ProductType, Record<string, KeyValue[]> | undefined> = {
    ram: ramChoices,
    cpu: cpuChoices,
    gpu: gpuChoices,
    mouse: mouseChoices,
    monitor: monitorChoices,
    keyboard: keyboardChoices,
    cpu_cooler: cpuCoolerChoices,
    case_fan: caseFanChoices,
    headset: headsetChoices,
    chair: chairChoices,
    mother: motherChoices,
    storage: storageChoices,
    power: powerChoices,
    case: caseChoices,
    microphone: undefined,
    accessories: undefined,
    thermal_paste: undefined,
    speakers: undefined,
    furniture: undefined,
    operative_system: undefined,
    other: undefined,
    build: undefined,
    notebook: undefined,
};
