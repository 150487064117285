import { Box, Divider, IconButton, TextField, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useEffect, useState } from 'react';

import colors from '../../../../theme/colors';
import { OptionalLteGte } from '../../../../types/OptionalLteGte';
import { useProductFiltersByProductTypeStyles } from '../styles';
import Filter from './Filter';

interface Props {
    label: string;
    selectedValue: OptionalLteGte;
    setValueGte: (value?: number) => void;
    setValueLte: (value?: number) => void;
}

const MinMaxFilter = ({ label, selectedValue, setValueGte, setValueLte }: Props) => {
    const classes = useProductFiltersByProductTypeStyles();

    const [userTypedValueGte, setUserTypedValueGte] = useState<string>('');
    const [userTypedValueLte, setUserTypedValueLte] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        setUserTypedValueGte(selectedValue.gte?.toString() ?? '');
        setUserTypedValueLte(selectedValue.lte?.toString() ?? '');
    }, [selectedValue]);

    const doChangeValue = () => {
        const gte = userTypedValueGte ? Number(userTypedValueGte) : undefined;
        const lte = userTypedValueLte ? Number(userTypedValueLte) : undefined;

        if (gte !== undefined && lte !== undefined && gte > lte) {
            setError('El valor mínimo no puede ser mayor que el valor máximo');
            return;
        }

        setError('');
        setValueGte(gte);
        setValueLte(lte);
    };

    return (
        <Filter label={label}>
            <Box display="flex" alignItems="center">
                <TextField
                    placeholder="Mínimo"
                    type="number"
                    variant="outlined"
                    value={userTypedValueGte}
                    inputProps={{ min: 0 }}
                    InputProps={{
                        className: `${classes.numberInput} ${error ? classes.errorInput : ''}`,
                    }}
                    onChange={(e) => {
                        if (!(Number(e.target.value) >= 0)) return;
                        setUserTypedValueGte(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') doChangeValue();
                    }}
                />
                <Divider style={{ margin: '0 5px', color: colors.grey, width: 10 }} />
                <TextField
                    placeholder="Máximo"
                    value={userTypedValueLte}
                    type="number"
                    variant="outlined"
                    inputProps={{ min: 0 }}
                    InputProps={{
                        className: `${classes.numberInput} ${error ? classes.errorInput : ''}`,
                    }}
                    onChange={(e) => {
                        if (!(Number(e.target.value) >= 0)) return;
                        setUserTypedValueLte(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') doChangeValue();
                    }}
                />
                <IconButton size="small" style={{ marginLeft: '20px' }} edge="end" disabled={userTypedValueGte === '' && userTypedValueLte === ''} onClick={doChangeValue}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            {error && (
                <Typography color="error" variant="body2">
                    {error}
                </Typography>
            )}
        </Filter>
    );
};

export default MinMaxFilter;
