import { OptionalLteGte } from '../../types/OptionalLteGte';
import { ProductType } from '../../types/ProductType';
import { SpecType } from '../../types/SpecType';

export interface SpecKeyValue {
    specKey: string;
    specValue: SpecValue;
}
export type SpecFilters = Record<string, Set<SpecValue> | OptionalLteGte>;
export interface SpecFilterDefinition {
    label: string;
    type: SpecType;
    options?: Record<string, SpecValue>;
}
export interface FiltersState {
    offersOnly: boolean;
    filterQ?: string;
    selectedBrands: Set<string>;
    selectedSpecs: SpecFilters;
    selectedPrice: OptionalLteGte;
    selectedProductType?: ProductType;
    availableSpecFilters?: Record<string, SpecFilterDefinition>;
    availableBrandFilters?: Record<string, string>;
}
export const filtersInitialState: FiltersState = {
    offersOnly: false,
    selectedBrands: new Set<string>(),
    selectedSpecs: {},
    selectedPrice: { gte: undefined, lte: undefined },
    availableBrandFilters: {},
    availableSpecFilters: {},
};

export type FiltersAction =
    | { type: 'SET_AVAILABLE_SPEC_FILTERS'; payload: Record<string, SpecFilterDefinition> }
    | { type: 'SET_OFFERS_ONLY'; payload: boolean }
    | { type: 'SET_SPEC_FILTERS'; payload: SpecFilters }
    | { type: 'ADD_SPEC_VALUE_FILTER'; payload: SpecKeyValue }
    | { type: 'SET_SPEC_FILTER_GTE'; payload: { specKey: string; gte?: number } }
    | { type: 'SET_SPEC_FILTER_LTE'; payload: { specKey: string; lte?: number } }
    | { type: 'REMOVE_SPEC_VALUE_FILTER'; payload: SpecKeyValue }
    | { type: 'REMOVE_SPEC_FILTER'; payload: string }
    | { type: 'RESET_FILTERS' }
    | { type: 'RESET_FILTERS_KEEPING_OFFERS' }
    | { type: 'SET_FILTER_Q'; payload?: string }
    | { type: 'CLEAR_FILTER_Q' }
    | { type: 'SET_PRICE_FILTER_LTE'; payload?: number }
    | { type: 'SET_PRICE_FILTER_GTE'; payload?: number }
    | { type: 'CLEAR_PRICE_FILTER' }
    | { type: 'SET_PRODUCT_TYPE'; payload?: ProductType }
    | { type: 'SET_AVAILABLE_BRAND_FILTERS'; payload: Record<string, string> }
    | { type: 'SET_BRAND_FILTERS'; payload: string[] }
    | { type: 'ADD_BRAND_FILTER'; payload: string }
    | { type: 'REMOVE_BRAND_FILTER'; payload: string };
export interface ContextActions {
    setProductType: (productType?: ProductType) => void;
    setOffersOnly: (offersOnly: boolean) => void;
    clearFilters: () => void;
    clearFiltersKeepingOffers: () => void;

    getSelectedValuesForSpec: (specKey: string) => SpecValue[] | OptionalLteGte;

    getFiltersQuantity: () => number;

    setAvailableSpecFilters: (specFilters: Record<string, SpecFilterDefinition>) => void;
    clearSpecFilters: () => void;
    setSpecFilters: (specFilters: SpecFilters) => void;
    addSpecFilter: (filter: SpecKeyValue) => void;
    removeSpecValueFilter: (filter: SpecKeyValue) => void;
    removeSpecFilter: (specKey: string) => void;

    setAvailableBrandFilters: (brands: Record<string, string>) => void;
    addBrandFilter: (brand: string) => void;
    setBrandFilters: (brands: string[]) => void;
    removeBrandFilter: (brand: string) => void;

    clearFilterQ: () => void;
    setFilterQ: (filterQ?: string) => void;

    clearPriceFilter: () => void;
    setPriceLte: (lte?: number) => void;
    setPriceGte: (gte?: number) => void;

    setSpecGteValue: (specKey: string, value?: number) => void;
    setSpecLteValue: (specKey: string, value?: number) => void;
}
export type ContextValue = [FiltersState, ContextActions];
export type SpecValue = string | boolean | number;
