import { Params } from 'react-router-dom';

import { ProductFiltersForCore as NormalizedFilters } from '../../api/types';
import { SpecValue } from '../../contexts/ProductsFilters/types';
import { helmetConfigForProductTypes, helmetProductsPageConfig } from '../../data/seo';
import { OptionalLteGte } from '../../types/OptionalLteGte';
import { ProductType } from '../../types/ProductType';

const getHelmetConfigForParams = (params: Params) => {
    if (!params.productTypeSlug) return helmetProductsPageConfig;

    const config = Object.values(helmetConfigForProductTypes).find(({ productTypeSlug }) => productTypeSlug === params.productTypeSlug);
    if (!config) return helmetProductsPageConfig;
    return config;
};

export const getHelmetTitle = (params: Params) => getHelmetConfigForParams(params).title;

export const getHelmetDescription = (params: Params) => getHelmetConfigForParams(params).description;

export const getSlugForProductType = (productType: ProductType): string | undefined => helmetConfigForProductTypes[productType].productTypeSlug;

const convertRecordSetValuesToArrays = <T, Y>(record: Record<string, Set<T> | Y>): Record<string, T[] | Y> =>
    Object.fromEntries(
        Object.entries(record).map(([key, value]): [string, T[] | Y] => {
            if (value instanceof Set) {
                return [key, Array.from(value)];
            }
            return [key, value];
        })
    );

export const serializeSpecs = (specs: Record<string, Set<SpecValue> | OptionalLteGte>): string => {
    const normalizedSpecs = normalizeSpecs(specs);
    const serializedSpecs = JSON.stringify(normalizedSpecs);
    return serializedSpecs;
};

export const unserializeSpecs = (serializedSpecs: string): Record<string, Set<SpecValue> | OptionalLteGte> => {
    const specs = JSON.parse(serializedSpecs) as Record<string, SpecValue[] | OptionalLteGte>;
    return Object.fromEntries(
        Object.entries(specs).map(([key, value]): [string, Set<SpecValue> | OptionalLteGte] => {
            if (isLteGteFilter(value)) return [key, value];
            return [key, new Set(value)];
        })
    );
};

type NormalizedSpec = SpecValue[] | OptionalLteGte;

export const normalizeSpecs = (specs: Record<string, Set<SpecValue> | OptionalLteGte>): Record<string, NormalizedSpec> | undefined => {
    if (Object.keys(specs).length > 0) {
        const specsWithoutSets = convertRecordSetValuesToArrays(specs);
        // esto se hace porque en la DB hay algunas specs que no están cargadas. Si tildan ambos valores de una booleana, se elimina la spec al enviar a core
        const filteredSpecs = removeBooleanSpecsWithBothValuesChecked(specsWithoutSets);
        return filteredSpecs;
    }
    return undefined;
};

export const normalizeFilters = ({ filterQ, price, brands, specs }: NormalizeFiltersParams): NormalizedFilters => {
    let filter: NormalizedFilters = {};
    if (filterQ) {
        filter = {
            ...filter,
            fuzzySearch: filterQ,
        };
    }
    if (price.lte || price.gte) {
        filter = {
            ...filter,
            price: {
                special: {
                    ARS: price,
                },
            },
        };
    }
    if (brands.size > 0) {
        filter = {
            ...filter,
            brand: Array.from(brands),
        };
    }

    filter = {
        ...filter,
        specs: normalizeSpecs(specs),
    };

    return filter;
};

export const removeBooleanSpecsWithBothValuesChecked = (specs: Record<string, SpecValue[] | OptionalLteGte>): Record<string, SpecValue[] | OptionalLteGte> => {
    const newSpecs = { ...specs };
    Object.entries(specs).forEach(([key, values]) => {
        if (isBooleanSpec(values) && values.length === 2 && values.includes(true) && values.includes(false)) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete newSpecs[key];
        }
    });
    return newSpecs;
};
export const isBooleanSpec = (specValues: SpecValue[] | OptionalLteGte): specValues is SpecValue[] => Array.isArray(specValues) && specValues.every((value) => typeof value === 'boolean');
export const isLteGteFilter = (specValues: SpecValue[] | OptionalLteGte): specValues is OptionalLteGte =>
    typeof specValues === 'object' && Object.keys(specValues).every((key) => ['gte', 'lte'].includes(key));
export interface NormalizeFiltersParams {
    filterQ?: string;
    price: OptionalLteGte;
    brands: Set<string>;
    specs: Record<string, Set<SpecValue> | OptionalLteGte>;
}
export const getTotalResultsText = (totalResults?: number): string => {
    if (totalResults === undefined) return 'Cargando...';
    return totalResults === 1 ? `${totalResults.toString()} resultado` : `${totalResults.toString()} resultados`;
};
