import { KeyValue } from '../../types/KeyValue';

const gpuTypes: KeyValue[] = [
    { key: 'amd', value: 'AMD' },
    { key: 'nvidia', value: 'Nvidia' },
];

export default {
    chip: gpuTypes,
};
