import { Box } from '@material-ui/core';
import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../../../contexts/ProductsFilters';
import colors from '../../../../../../theme/colors';
import OpenFiltersButton from './OpenFiltersButton';
import SelectedFilterIndicator from './SelectedFilterIndicator';

interface Props {
    openFilters: () => void;
    totalResults?: number;
}

const FiltersStatusBar = ({ openFilters, totalResults }: Props) => {
    const [{ selectedProductType, offersOnly }] = useContext(ProductsFiltersContext);
    const hasFilters = selectedProductType !== undefined || offersOnly;
    return (
        <Box bgcolor={'#F1F1F1'} border={1} borderColor={colors.lightGrey} height={46}>
            <Box display={'flex'} alignItems={'center'} height={'100%'}>
                {hasFilters ? (
                    <Box borderColor={colors.lightGrey} borderRight={'1px solid ' + colors.lightGrey} flexGrow={1} pl={2}>
                        <SelectedFilterIndicator totalResults={totalResults} />
                    </Box>
                ) : null}
                <OpenFiltersButton onClick={openFilters} fullWidth={!hasFilters} />
            </Box>
        </Box>
    );
};

export default FiltersStatusBar;
