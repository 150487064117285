import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import API from '../../api/API';
import { UserContext } from '../../contexts/User';
import { helmetUserProfileConfig } from '../../data/seo';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import useSnackbarGD from '../../hooks/useSnackbar';
import PageContent from '../components/PageContent';
import CollapsibleOrders from './CollapsibleOrders';
import CompletedOrders from './CompletedOrders';
import CurrentOrders from './CurrentOrders';
import UserData from './UserData';
import UserInfoCard from './UserInfoCard';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const COLLAPSIBLE_ORDER_TYPES = {
    rma: 'rma',
    cancelled: 'cancelled',
};

const UserProfile = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbarMessage } = useSnackbarGD();
    const [error, setError] = useState(null);
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [areOrdersLoaded, setAreOrdersLoaded] = useState(false);
    const [user, setUser] = useState([]);
    const [orders, setOrders] = useState([]);
    const [, dispatch] = useContext(UserContext);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);
    useEffect(() => {
        API.users
            .me()
            .then((response) => {
                setUser(response.data);
            })
            .catch((err) => {
                setError(err.message);
                handler.handleError({ error: err, userContextDispatch: dispatch, showSnackbarMessage });
            })
            .finally(() => setIsUserLoaded(true));
        API.orders
            .get()
            .then((response) => {
                setOrders(response.data);
            })
            .catch((err) => {
                if (err.response?.status !== 401) {
                    showSnackbarMessage('Hubo un problema recuperar tus compras. Por favor intentá nuevamente en unos minutos', 'error');
                }
                handler.handleError({
                    error: err,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                });
            })
            .finally(() => setAreOrdersLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, dispatch]);

    if (error) {
        return (
            <PageContent>
                Error:
                {error}
            </PageContent>
        );
    }
    if (!isUserLoaded) {
        return (
            <PageContent>
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            </PageContent>
        );
    }
    return (
        <>
            <Helmet>
                <title>{helmetUserProfileConfig.title}</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content={helmetUserProfileConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetUserProfileConfig.title} />
                <meta property="og:description" content={helmetUserProfileConfig.description} />
            </Helmet>
            <PageContent>
                <Box display="flex" className={classes.root}>
                    <UserInfoCard userPicture={user.profileImageURL ?? null} name={`${user.first_name} ${user.last_name}`} />
                    <Box pl={{ xs: 0, sm: 2, md: 3 }} flexGrow={1}>
                        <UserData user={user} />
                        {areOrdersLoaded ? (
                            <Box>
                                <CurrentOrders orders={orders} />
                                <CompletedOrders orders={orders} />
                                <CollapsibleOrders orders={orders} ordersType={COLLAPSIBLE_ORDER_TYPES.rma} />
                                <CollapsibleOrders orders={orders} ordersType={COLLAPSIBLE_ORDER_TYPES.cancelled} />
                            </Box>
                        ) : (
                            <Box>
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>
                </Box>
            </PageContent>
        </>
    );
};

export default UserProfile;
