import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { jwtDecode } from 'jwt-decode';
import { useContext, useState } from 'react';

import API from '../../../../api/API';
import { UserContext } from '../../../../contexts/User';
import userConstants from '../../../../contexts/User/userConstants';
import handler from '../../../../helpers/handler';
import storageHelper from '../../../../helpers/storageHelper';
import useSnackbarGD from '../../../../hooks/useSnackbar';
import GoogleLoginButton from '../../../authentication/GoogleLoginButton';
import CustomDivider from '../../../components/CustomDivider';

const useStyles = makeStyles(() => ({
    register: {
        margin: 0,
        maxWidth: 302,
    },
    emailInput: {
        marginTop: '31px',
    },
    passwordConfirmation: {
        marginTop: '-29px',
    },
}));

const CheckoutRegister = ({ goToNextStep, openLogin }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [, dispatch] = useContext(UserContext);
    const [, setLoading] = useState(false);
    const { showSnackbarMessage } = useSnackbarGD();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleSubmit = (event) => {
        event.preventDefault();
        const newUser = {
            email,
            password,
        };
        setLoading(true);

        if (password !== passwordConfirmation) {
            showSnackbarMessage('Las contraseñas no coinciden', 'warning');
            return;
        }
        const cart = storageHelper.getUserCart();
        API.auth
            .register(newUser)
            .then((response) => {
                const { token } = response.data;
                const decodedToken = jwtDecode(token);
                const user = { email, role: decodedToken.role };
                dispatch({ type: userConstants.SET_USER, user });
                storageHelper.setUser(user);
                if (cart?.id) API.carts.updateCart(cart);

                setEmail('');
                setPassword('');
                setPasswordConfirmation('');
                goToNextStep();
            })
            .catch((error) => {
                if (error.response?.status === 400) showSnackbarMessage('Tu contraseña no cumple con los requisitos mínimos.', 'warning');
                else if (error.response?.status === 403) showSnackbarMessage('Ya existe un usuario con este email.', 'error');
                else {
                    const customErrorMessage = 'Hubo un problema intentando registrar tu cuenta. Por favor intentá nuevamente en unos minutos';
                    handler.handleError({
                        error,
                        userContextDispatch: dispatch,
                        showSnackbarMessage,
                        customErrorMessage,
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangePassword = (event) => setPassword(event.target.value);
    const handleChangePasswordConfirmation = (event) => setPasswordConfirmation(event.target.value);

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        storageHelper.waitGoogleLogin();
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
    };

    return (
        <div>
            <Box mt={1} display="flex">
                <Typography variant="h4">Crear cuenta</Typography>
            </Box>
            <Box mt={1} display="flex">
                <Typography variant="subtitle1">Creá tu cuenta para poder continuar con la compra.</Typography>
            </Box>
            <Box display="flex">
                <Typography variant="subtitle1">
                    ¿Ya tenés una cuenta en Gamer Factory?{' '}
                    <Button variant="text" onClick={openLogin}>
                        INICIAR SESIÓN
                    </Button>
                </Typography>
            </Box>
            <Box textAlign="center">
                <Box className={classes.register}>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <TextField
                                type="email"
                                autoComplete="username"
                                placeholder="Email"
                                value={email}
                                InputProps={{
                                    className: classes.emailInput,
                                }}
                                onChange={handleChangeEmail}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                placeholder="Contraseña"
                                autoComplete="new-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handleChangePassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box mt={1} display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start">
                                <Typography variant="caption" align="left" color="textSecondary">
                                    La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico
                                </Typography>
                            </Box>
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                placeholder="Repetir contraseña"
                                autoComplete="new-password"
                                className={classes.passwordConfirmation}
                                type={showPassword ? 'text' : 'password'}
                                value={passwordConfirmation}
                                onChange={handleChangePasswordConfirmation}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>

                        <Box mt={4} mb={2}>
                            <FormControl fullWidth>
                                <Button variant="contained" type="submit">
                                    CREAR CUENTA
                                </Button>
                            </FormControl>
                        </Box>
                    </form>

                    <CustomDivider />
                    <GoogleLoginButton onClick={handleGoogleLogin} />
                </Box>
            </Box>
        </div>
    );
};

export default CheckoutRegister;
