import { Box, Button, Typography } from '@material-ui/core';
import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../../../../contexts/ProductsFilters';
import filterIcon from '../../../../filter_icon.svg';
import { useMobileFiltersStatusBarStyles } from '../../../styles';

interface OpenFiltersButtonProps {
    onClick: () => void;
    fullWidth: boolean;
}

const OpenFiltersButton = ({ onClick, fullWidth }: OpenFiltersButtonProps) => {
    const [, { getFiltersQuantity }] = useContext(ProductsFiltersContext);
    const filtersQuantity = getFiltersQuantity();
    const classes = useMobileFiltersStatusBarStyles();
    return (
        <Box display="flex" width={fullWidth ? '100%' : 'auto'} px={2}>
            <Button onClick={onClick} fullWidth style={{ textTransform: 'none', justifyContent: 'flex-start' }}>
                <Box display="flex" alignItems="center" my={0.5}>
                    <img alt="filter icon" src={filterIcon} className={classes.filterIcon} />
                    <Typography className={classes.filterLabel}>Filtros {filtersQuantity > 0 ? `(${filtersQuantity.toString()})` : ''}</Typography>
                </Box>
            </Button>
        </Box>
    );
};

export default OpenFiltersButton;
