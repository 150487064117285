import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputAdornment, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { jwtDecode } from 'jwt-decode';
import { useContext, useState } from 'react';

import API from '../../api/API';
import { UserContext } from '../../contexts/User';
import userConstants from '../../contexts/User/userConstants';
import storageHelper from '../../helpers/storageHelper';
import useSnackbarGD from '../../hooks/useSnackbar';
import LoadingButton from '../LoadingButton';
import CustomDivider from '../components/CustomDivider';
import GoogleLoginButton from './GoogleLoginButton';

const useStyles = makeStyles(() => ({
    login: {
        margin: 'auto',
        maxWidth: 302,
    },
    rememberMeLabel: {
        marginLeft: 0,
    },
    forgotPassword: {
        fontWeight: 'lighter',
    },
    checkboxFormControl: {
        marginLeft: 0,
    },
    uncheckedCheckbox: {
        content: 'url(/images/uncheckedCheckbox.svg)',
    },
    checkedCheckbox: {
        content: 'url(/images/checkedCheckbox.svg)',
    },
    emailInput: {
        marginTop: '31px',
    },
    closeIcon: {
        color: '#727272',
    },
}));

const LoginModal = ({ open, setOpen, setRegisterOpen, openForgotPasswordModal }) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, dispatch] = useContext(UserContext);
    const { showSnackbarMessage } = useSnackbarGD();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickRememberMe = () => setRememberMe(!rememberMe);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const cart = storageHelper.getUserCart();

        API.auth
            .login({ email, password, rememberMe })
            .then((response) => {
                const { token } = response.data;
                const decodedToken = jwtDecode(token);
                const user = {
                    email,
                    role: decodedToken.role,
                    profileImageURL: decodedToken.profileImageURL,
                };
                dispatch({ type: userConstants.SET_USER, user });
                storageHelper.setUser(user);
                if (cart?.id) API.carts.updateCart(cart);

                showSnackbarMessage(`Bienvenido, ${user.email}`, 'success');
                setEmail('');
                setPassword('');
                handleClose();
            })
            .catch((error) => {
                if (error.response?.status === 401) showSnackbarMessage('Tu usuario y/o contraseña no parecen ser válidos. Por favor revisalos e intentá nuevamente.', 'error');
                else showSnackbarMessage('Hubo un problema intentando iniciar sesión. Por favor intentá nuevamente en unos minutos', 'warning');
            })
            .finally(() => setLoading(false));
    };

    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangePassword = (event) => setPassword(event.target.value);

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        storageHelper.waitGoogleLogin();
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ square: true }} fullWidth maxWidth="xs">
                <DialogContent>
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <Button onClick={handleClose} className={classes.closeIcon}>
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h4">Iniciar sesión</Typography>
                    </Box>

                    <Box textAlign="center">
                        <Box className={classes.login}>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="username"
                                        value={email}
                                        InputProps={{
                                            className: classes.emailInput,
                                        }}
                                        onChange={handleChangeEmail}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Contraseña"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        onChange={handleChangePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton tabIndex="-1" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box display="flex" mt={1} justifyContent="space-between">
                                        <Box display="flex" justifyContent="flex-start">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="rememberMe"
                                                        onChange={handleClickRememberMe}
                                                        className={rememberMe ? classes.checkedCheckbox : classes.uncheckedCheckbox}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="subtitle2" className={classes.rememberMeLabel}>
                                                        Recordarme
                                                    </Typography>
                                                }
                                                className={classes.checkboxFormControl}
                                            />
                                        </Box>
                                        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                                            <Button className={classes.forgotPassword} onClick={openForgotPasswordModal}>
                                                ¿Olvidaste la contraseña?
                                            </Button>
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box mt={4} mb={2}>
                                    <FormControl fullWidth>
                                        <LoadingButton loading={loading} variant="contained" type="submit">
                                            Iniciar sesión
                                        </LoadingButton>
                                    </FormControl>
                                </Box>
                            </form>
                            <CustomDivider />
                            <GoogleLoginButton onClick={handleGoogleLogin} disabled={loading} />
                        </Box>
                        <Box mb={4}>
                            <Typography variant="subtitle1" align="center">
                                {'¿No tenés cuenta? '}

                                <Button variant="text" onClick={setRegisterOpen} alt="Register">
                                    CREAR CUENTA
                                </Button>
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LoginModal;
