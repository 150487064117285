import { Box, Grid } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import API from '../../api/API';
import { UserContext } from '../../contexts/User';
import userConstants from '../../contexts/User/userConstants';
import NoResultsMessage from '../components/NoResultsMessage';
import ProductsSkeleton from '../product/ProductsSkeleton';
import CartLinkedProductCard from './CartLinkedProductCard';

const ProductsList = ({ products, totalPages, currentPage, getProducts, isLoading }) => {
    const [userState, dispatchUser] = useContext(UserContext);

    useEffect(() => {
        const { cart } = userState.user;
        if (!cart?.id) {
            const createCart = async () => {
                API.carts.post().then((response) => {
                    dispatchUser({
                        type: userConstants.UPDATE_CART,
                        cart: response.data,
                    });
                });
            };
            createCart();
        }
    }, [dispatchUser, userState]);

    return (
        <Box mt={0} mb={2}>
            {products?.length > 0 && (
                <InfiniteScroll dataLength={products?.length} next={getProducts} hasMore={currentPage < totalPages} style={{ overflow: 'inherit' }} scrollThreshold="800px">
                    <Grid container spacing={2}>
                        {products?.map((product) => (
                            <Grid key={product.id} item xs={6} sm={4} md={4} lg={3} xl={2}>
                                <CartLinkedProductCard product={product} multiSelect />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll>
            )}
            {isLoading && <ProductsSkeleton showSortSkeleton={false} marginLeft={0} />}
            {!isLoading && products?.length === 0 && (
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" minHeight="80vh">
                    <NoResultsMessage from="ecommerce" />
                </Box>
            )}
        </Box>
    );
};

export default ProductsList;
