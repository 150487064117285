import { Box, ButtonBase, Typography, makeStyles } from '@material-ui/core';
import { useState } from 'react';

import { peripheralTypesDefinitions } from '../../../../helpers/types';
import peripheralCheckbox from '../../images/peripheralCheckbox.svg';
import BaseProductMultiSelect from '../BaseProductMultiSelect';

const useStyles = makeStyles(() => ({
    product: {
        position: 'relative',
        border: '1px solid #DBDBD5',
        height: 111,
        width: 111,
        '&:hover, &$focusVisible': {
            background: '#F8E837',
            zIndex: 1,
            '& h6': {
                fontWeight: '700',
            },
        },
    },
    selected: {
        background: '#F8E837',
        zIndex: 1,
        '& h6': {
            fontWeight: '700',
        },
        '&::after': {
            content: `url(${peripheralCheckbox})`,
            display: 'block',
            position: 'absolute',
            right: 44,
            top: '92%',
            zIndex: '-1',
        },
    },
}));

const PeripheralsSelect = ({ updateBuildSpecs, buildSpecs, setGoToNextAllowed }) => {
    const classes = useStyles();
    const [selectedPeripheralType, setSelectedPeripheralType] = useState(peripheralTypesDefinitions[0]);

    const validateNextStepAllowed = () => setGoToNextAllowed(true);

    return (
        <>
            <Box pl={4} pt={3} pb={2}>
                {peripheralTypesDefinitions.map((peripheral) => {
                    const titleToUse = peripheral.peripheralTitle ?? peripheral.title;
                    return (
                        <ButtonBase //FIXME esto (y el map) es idéntico a HomePeripheralsButton, buscar la forma de juntar
                            focusRipple
                            key={titleToUse}
                            className={`${classes.product} ${selectedPeripheralType?.title === titleToUse ? classes.selected : ''}`}
                            style={{
                                backgroundImage: `url(${peripheral.image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: '111px 111px',
                            }}
                            onClick={() => setSelectedPeripheralType(peripheral)}
                        >
                            <Box display="flex" justifyContent="center" flexDirection="column" mt={10}>
                                <Typography variant="subtitle1">{titleToUse}</Typography>
                            </Box>
                        </ButtonBase>
                    );
                })}
            </Box>
            <BaseProductMultiSelect
                buildProduct={buildSpecs.peripherals}
                buildSpecs={buildSpecs}
                updateBuildSpecs={updateBuildSpecs}
                validateNextStepAllowed={validateNextStepAllowed}
                productType={selectedPeripheralType.type}
                additionalProductSpecsFilter={selectedPeripheralType.peripheralInitialSpecsFilter}
                peripherals
            />
        </>
    );
};

export default PeripheralsSelect;
