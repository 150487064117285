import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../api/API';
import { SortDefinition } from '../../api/types';
import { ProductFiltersForCore } from '../../api/types';
import { ProductsFiltersContext } from '../../contexts/ProductsFilters';
import { UserContext } from '../../contexts/User';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import { SORT_VALUES, SortValue, createSortObject } from '../../helpers/sortOptionsHelper';
import useWindowDimensions from '../../helpers/useWindowsDimentions';
import useSnackbarGD from '../../hooks/useSnackbar';
import { Product } from '../../types/Product';
import useURLFiltersSync from './useURLFiltersSync';
import { normalizeFilters } from './utils';

const getProductsPerPage = (width: number) => {
    if (width < 1280) {
        return 12;
    }
    if (width < 1920) {
        return 16;
    }
    if (width < 2880) {
        return 24;
    }
    return 36;
};

interface ProductSearchParams {
    type?: string;
    page: number;
    perPage: number;
    filter: ProductFiltersForCore;
    sort: SortDefinition;
    tag?: string;
}

const useProductsPage = () => {
    const { showSnackbarMessage } = useSnackbarGD();
    const navigate = useNavigate();
    const [{ selectedBrands: selectedBrands, selectedSpecs, selectedPrice: selectedPrice, filterQ, selectedProductType, offersOnly }] = useContext(ProductsFiltersContext);

    const [sort, setSort] = useState<SortValue>(SORT_VALUES.popularity);
    const syncFromURLDone = useURLFiltersSync({ sort, setSort });

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>();
    const [totalResults, setTotalResults] = useState<number>();
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>();
    const [perPage, setPerPage] = useState<number>();

    const { width } = useWindowDimensions();
    const [, dispatch] = useContext(UserContext);

    useEffect(() => {
        setPerPage(getProductsPerPage(width));
        if (width >= 960) {
            setShowMobileFilters(false);
        }
    }, [width]);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    const getProducts = useCallback(
        ({ cleanProducts = false } = {}) => {
            setIsLoading(true);

            if (cleanProducts) {
                setProducts(undefined);
                setTotalResults(undefined);
            }
            const nextPage = cleanProducts ? 1 : currentPage + 1;
            const dataForSearch: ProductSearchParams = {
                type: selectedProductType,
                page: nextPage,
                perPage: getProductsPerPage(width),
                filter: normalizeFilters({
                    filterQ,
                    price: selectedPrice,
                    brands: selectedBrands,
                    specs: selectedSpecs,
                }),
                sort: createSortObject(sort),
            };
            if (offersOnly) {
                dataForSearch.tag = 'oferta';
            }

            API.products
                .search(dataForSearch)
                .then(({ data: { data, total } }) => {
                    setTotalResults(total);
                    setTotalPages(Math.ceil(total / getProductsPerPage(width)));
                    setProducts((prevProducts) => {
                        if (cleanProducts) {
                            return data;
                        }
                        const newProducts = prevProducts ?? [];
                        return [...newProducts, ...data];
                    });
                    setCurrentPage(nextPage);
                    setIsLoading(false);
                })
                .catch((error: unknown) => {
                    void handler.handleError({
                        error,
                        userContextDispatch: dispatch,
                        showSnackbarMessage,
                        navigate,
                    });
                    setIsLoading(false);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPage, filterQ, offersOnly, selectedBrands, selectedPrice, selectedProductType, selectedSpecs, sort, width]
    );

    return {
        sort,
        perPage,
        isLoading,
        products,
        location,
        totalPages,
        currentPage,
        showMobileFilters,
        setSort,
        setIsLoading,
        setShowMobileFilters,
        getProducts,
        syncFromURLDone,
        totalResults,
    };
};

export default useProductsPage;
