/* eslint-disable react/jsx-props-no-spreading */
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    search: {
        backgroundColor: '#F8E837',
        marginRight: theme.spacing(2),
        borderBottom: '1px solid #1C1C1C',
        marginLeft: 0,
        width: '90%',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        '@media (max-width: 960px)': {
            marginLeft: '30px',
        },
    },
    // dropdown: {
    //     top: '38px',
    //     width: '100%',
    //     zIndex: '9999',
    //     display: 'flex',
    //     borderRadius: '3px',
    //     padding: '8px 14px',
    //     position: 'absolute',
    //     border: `1px solid ${colors.blackGrey}`,
    //     background: colors.white,
    // },
    // dropdownList: {
    //     width: '100%',
    //     margin: '0px',
    //     padding: '0px',
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    // dropdownItem: {
    //     width: '100%',
    //     margin: '0px',
    //     display: 'block',
    //     cursor: 'pointer',
    //     overflow: 'hidden',
    //     padding: '5px 10px',
    //     borderRadius: '3px',
    //     marginBottom: '6px',
    //     whiteSpace: 'nowrap',
    //     textOverflow: 'ellipsis',
    //     '&:hover': {
    //         background: colors.yellow,
    //     },
    //     '&:last-child': {
    //         marginBottom: '0px',
    //     },
    // },
}));

export default function GDSearcher() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [filterQ, setFilterQ] = useState('');

    /*
    const [predictedResults, setPredictedResults] = useState([]);
    const fetchPrediction = () => {
        API.products.predict(filterQ)
            .then(({ data }) => {
                setPredictedResults(data);
            });
    };
    useEffect(() => {
        let time = 0;
        if (filterQ && filterQ.length) {
            time = 500;
        } else {
            setPredictedResults([]);
        }
        const timer = setTimeout(() => {
            if (time !== 0) {
                fetchPrediction();
            }
        }, time);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
      }, [filterQ]);

      const handleSeeProductPage = (code) => {
          navigate(`/product/${code}`);
          setPredictedResults([]);
      };
    */

    const handleChangeValue = (event) => {
        setFilterQ(event.target.value);
    };

    const handleRedirectToProducts = () => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('q', filterQ);
        navigate(`/productos?${urlSearchParams}`);
        setFilterQ('');
    };

    return (
        <Box className={classes.search}>
            <InputBase
                value={filterQ}
                onChange={handleChangeValue}
                style={{
                    width: '93%',
                    border: 'none',
                    backgroundColor: '#ffffff00',
                    fontSize: 14,
                    outline: 'none',
                    fontFamily: 'Frutiger!important',
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') handleRedirectToProducts();
                }}
                placeholder="Buscar..."
                type="text"
            />
            <IconButton style={{ padding: 0 }} onClick={() => handleRedirectToProducts()}>
                <SearchIcon style={{ color: '#1C1C1C' }} />
            </IconButton>
            {/* {
                filterQ && predictedResults?.length ? (
                    <Box className={classes.dropdown}>
                        <List className={classes.dropdownList}>
                            {
                                predictedResults.map((search) => (
                                    <ListItem
                                        key={search.id}
                                        className={classes.dropdownItem}
                                        onClick={() => handleSeeProductPage(search.code)}
                                    >
                                        {search.name}
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                ) : null
            } */}
        </Box>
    );
}
