import { KeyValue } from '../../types/KeyValue';
import { CoolerTypes } from '../types';
import boolean from './boolean';

const types: KeyValue[] = [
    { key: CoolerTypes.AIR_COOLER, value: 'Air Cooler' },
    { key: CoolerTypes.WATER_COOLER, value: 'Water Cooler' },
];

const lighting: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'none', value: 'Sin iluminación' },
];

const fanSizes: KeyValue[] = [
    { key: '92mm', value: '92mm' },
    { key: '120mm', value: '120mm' },
    { key: '140mm', value: '140mm' },
    { key: 'dual_tower', value: 'Dual tower' },
];

export default {
    fanSize: fanSizes,
    lighting: lighting,
    cooler_type: types,
    screen: boolean,
};
