import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

const connectivities: KeyValue[] = [
    { key: 'wired', value: 'Cableado' },
    { key: 'wireless', value: 'Inalámbrico' },
    { key: 'hybrid', value: 'Híbrido' },
];

const lightingTypes: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'fixedColor', value: 'Color fijo' },
    { key: 'noLighting', value: 'Sin iluminación' },
];

export default {
    adjustableDPI: boolean,
    connectivity: connectivities,
    lighting: lightingTypes,
    programmableButtons: boolean,
};
