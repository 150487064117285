import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

export const types: KeyValue[] = [
    { key: 'internal', value: 'Internal' },
    { key: 'external', value: 'External' },
];

export const connections: KeyValue[] = [
    { key: 'M2', value: 'M2' },
    { key: 'SATA', value: 'SATA' },
    { key: 'USB', value: 'USB' },
];

export const protocols: KeyValue[] = [
    { key: 'SATA3', value: 'SATA 3' },
    { key: 'NVME', value: 'NVMe' },
];

export const technologies: KeyValue[] = [
    { key: 'SSD', value: 'SSD' },
    { key: 'HDD', value: 'HDD' },
];

export default {
    type: types,
    connection: connections,
    protocol: protocols,
    technology: technologies,
    notebookCompatible: boolean,
};
