import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ImageRadio from '../../../ImageRadio';
import amd from '../../images/amd.svg';
import amdSelected from '../../images/amdselected.svg';
import intel from '../../images/intel.svg';
import intelSelected from '../../images/intelselected.svg';

const useStyles = makeStyles(() => ({
    box: {
        maxWidth: 605,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

const CPUBrandSelect = ({ updateBuildSpecs, buildSpecs }) => {
    const handleClick = (cpuBrandSelected) => () => {
        if (buildSpecs.cpuBrand === cpuBrandSelected) updateBuildSpecs({ cpuBrand: null });
        else updateBuildSpecs({ cpuBrand: cpuBrandSelected });
    };

    const classes = useStyles();

    return (
        <>
            <Box mb={2}>
                <Typography variant="subtitle1">La marca del procesador define todos los componentes de la PC.</Typography>
            </Box>
            <Box className={classes.box} my={4} display="flex">
                <ImageRadio
                    key="intel"
                    image={buildSpecs.cpuBrand === 'intel' ? intelSelected : intel}
                    title="Intel"
                    onClick={handleClick('intel')}
                    checked={buildSpecs.cpuBrand === 'intel'}
                    defaultImage={intel}
                />
                <ImageRadio
                    key="amd"
                    image={buildSpecs.cpuBrand === 'amd' ? amdSelected : amd}
                    title="AMD"
                    onClick={handleClick('amd')}
                    checked={buildSpecs.cpuBrand === 'amd'}
                    defaultImage={amd}
                />
            </Box>
        </>
    );
};

export const defaultCpuBrandValue = null;
export default CPUBrandSelect;
