import { makeStyles } from '@material-ui/core';

export const useProductsPageStyles = makeStyles(() => ({
    filterSpecButton: {
        justifyContent: 'flex-start',
        margin: 0,
        padding: '1px 0',
        color: '#727272',
        fontWeight: 300,
        textTransform: 'none',
        fontSize: '14px',
    },
}));

export const useProductFiltersStyles = makeStyles(() => ({
    filterCategoryTitle: {
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
    },
    filterCategoryTitleAndLink: {
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const useProductListStyles = makeStyles((theme) => ({
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));
