import { Box, Container, Grid, Hidden, useTheme } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import API from '../../api/API';
import { ProductsFiltersContext } from '../../contexts/ProductsFilters';
import { helmetOffersPageConfig } from '../../data/seo';
import DesktopProductFilters from './DesktopProductFilters';
import ProductsList from './ProductsList';
import FiltersStatusBar from './components/Filter/Mobile/FiltersStatusBar/FiltersStatusBar';
import MobileFiltersDialog from './components/Filter/Mobile/MobileFiltersDialog';
import OrderSelect from './components/OrderSelect';
import useProductsPage from './useProductsPage';
import { getHelmetDescription, getHelmetTitle } from './utils';

interface Props {
    isOffersPage?: boolean;
}

const ProductsPage = ({ isOffersPage = false }: Props) => {
    const [{ filterQ, offersOnly, selectedBrands, selectedPrice, selectedProductType, selectedSpecs }, { setAvailableSpecFilters, setAvailableBrandFilters }] =
        useContext(ProductsFiltersContext);
    const params = useParams();

    const {
        sort,
        setSort,

        products,
        getProducts,
        isLoading,

        totalResults,
        totalPages,
        currentPage,

        showMobileFilters,
        setShowMobileFilters,

        syncFromURLDone,
    } = useProductsPage();
    const pageTitle = isOffersPage ? helmetOffersPageConfig.title : getHelmetTitle(params);

    useEffect(() => {
        if (!selectedProductType) return;
        API.products
            .filters({ type: selectedProductType })
            .then(({ data }) => {
                setAvailableSpecFilters(data.data.specs);
                setAvailableBrandFilters(data.data.brands);
            })
            .catch((error: unknown) => {
                console.error(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProductType]);

    useEffect(() => {
        if (syncFromURLDone) getProducts({ cleanProducts: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncFromURLDone, filterQ, offersOnly, selectedBrands, selectedPrice, selectedProductType, selectedSpecs, sort]);

    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={isOffersPage ? helmetOffersPageConfig.description : getHelmetDescription(params)} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={isOffersPage ? helmetOffersPageConfig.description : getHelmetDescription(params)} />
            </Helmet>
            <>
                <MobileFiltersDialog open={showMobileFilters} setOpen={setShowMobileFilters} totalResults={totalResults} />
                {!showMobileFilters && (
                    <Box position="relative">
                        <Hidden mdUp>
                            <Box position={'fixed'} width={'100%'} zIndex={theme.zIndex.appBar - 1}>
                                <FiltersStatusBar
                                    openFilters={() => {
                                        setShowMobileFilters(true);
                                    }}
                                    totalResults={totalResults}
                                />
                            </Box>
                        </Hidden>
                        <Box position={'relative'} top={{ xs: 54, md: 0 }} mb={{ xs: 8 }}>
                            <Container maxWidth="xl">
                                <OrderSelect sort={sort} setSort={setSort} />
                                <Grid container spacing={3}>
                                    <Grid item xs={false} md={3} lg={3} xl={2}>
                                        <Hidden smDown>
                                            <DesktopProductFilters totalResults={totalResults} />
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9} xl={10}>
                                        <ProductsList products={products} totalPages={totalPages} getProducts={getProducts} currentPage={currentPage} isLoading={isLoading} />
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                )}
            </>
        </>
    );
};

export default ProductsPage;
