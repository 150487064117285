import { Box, Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Dispatch, SetStateAction } from 'react';

import ProductTypeSelection from '../../../ProductTypeSelection';

interface Props {
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const ProductSelectionDialogContent = ({ setOpen }: Props) => (
    <>
        <Box pt={1}>
            <IconButton
                onClick={() => {
                    setOpen(false);
                }}
            >
                <ArrowBackIcon style={{ fill: '#1C1C1C' }} />
            </IconButton>
        </Box>
        <Box overflow={'auto'} maxHeight={'100vh'}>
            <Container>
                <ProductTypeSelection
                    onSelected={() => {
                        setOpen(false);
                    }}
                />
            </Container>
        </Box>
    </>
);

export default ProductSelectionDialogContent;
