import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../theme/colors';
import gameCheckbox from '../../pro-builder/images/gameCheckbox.svg';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
    },
    card: {
        width: '100%',
        position: 'relative',
    },
    mediaWrapper: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
    },
    addProduct: {
        width: '100%',
    },
    removeProduct: {
        width: '100%',
        backgroundColor: colors.black,
        color: colors.yellow,
    },
    productTitleWrapper: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: 2,
        wordBreak: 'keep-all',
        overflow: 'hidden',
        minHeight: 39,
        textAlign: 'center',
    },
    productTitle: {
        color: colors.blackGrey,
        fontSize: 13,
    },
    checked: {
        position: 'relative',
        height: 'auto',
        '&::after': {
            content: `url(${gameCheckbox})`,
            width: 25,
            height: 26,
            display: 'block',
            position: 'absolute',
            right: -12,
            top: -13,
        },
        '& .MuiPaper-root': {
            boxShadow: '0px 2px 1px -1px #f8e837, 0px 1px 1px 0px #f8e837, 0px 1px 3px 0px #f8e837',
        },
    },
    label: {
        display: 'inline-block',
        background: colors.yellow,
        position: 'absolute',
        left: 0,
        width: 'auto',
        zIndex: 1,
    },
    strikethroughPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        color: colors.blackGrey,
    },
    cardContent: {
        padding: 0,
    },
    cardBottomActions: {
        padding: '0px 22px 22px 22px',
        display: 'block',
    },
}));

export default useStyles;
