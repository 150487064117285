import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';
import motherChoices from './motherChoices';

const sizes: KeyValue[] = [
    { key: 'atx', value: 'ATX' },
    { key: 'sfx', value: 'SFX' },
];

const powerSupplySlotLocations: KeyValue[] = [
    { key: 'top', value: 'Superior' },
    { key: 'bottom', value: 'Inferior' },
];

const lightings: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'noLighting', value: 'Sin iluminación' },
];

const formFactors: KeyValue[] = [
    { key: 'Full-Tower', value: 'Full Tower' },
    { key: 'Mid-Tower', value: 'Mid Tower' },
    { key: 'Mini-Tower', value: 'Mini Tower' },
];

export default {
    powerSupplyIncluded: boolean,
    peripheralsIncluded: boolean,
    mother_form_factor: motherChoices.formFactors,
    size: sizes,
    powerSupplySlotLocation: powerSupplySlotLocations,
    lighting: lightings,
    form_factor: formFactors,
    compatibilityWithWatercooler120: boolean,
    compatibilityWithWatercooler140: boolean,
    compatibilityWithWatercooler240: boolean,
    compatibilityWithWatercooler280: boolean,
    compatibilityWithWatercooler360: boolean,
    compatibilityWithWatercooler420: boolean,
};
