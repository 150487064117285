import { Box, Button, Container, Divider } from '@material-ui/core';
import { Dispatch, SetStateAction, useContext } from 'react';

import { ProductsFiltersContext } from '../../../../../../contexts/ProductsFilters';
import FiltersAsChips from '../../../FiltersAsChips';
import ProductFiltersForProductType from '../../../ProductFiltersForProductType';
import SelectedProductType from '../../../SelectedProductType';

interface Props {
    setOpen: Dispatch<SetStateAction<boolean>>;
    totalResults?: number;
}

const FiltersForProductTypeDialogContent = ({ setOpen, totalResults }: Props) => {
    const [{ selectedProductType }, { clearFiltersKeepingOffers }] = useContext(ProductsFiltersContext);
    const onProductTypeUnselected = () => {
        clearFiltersKeepingOffers();
        setOpen(false);
    };

    if (!selectedProductType) return null;

    return (
        <>
            <Box>
                <Container>
                    <SelectedProductType onProductTypeUnselected={onProductTypeUnselected} productType={selectedProductType} totalResults={totalResults} />
                    <FiltersAsChips />
                </Container>
            </Box>
            <Divider orientation="horizontal" />

            <Box overflow={'auto'} py={1}>
                <Container>
                    <ProductFiltersForProductType />
                </Container>
            </Box>
            <Divider orientation="horizontal" />
            <Box mt={2}>
                <Container>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        VER PRODUCTOS
                    </Button>
                </Container>
            </Box>
        </>
    );
};

export default FiltersForProductTypeDialogContent;
