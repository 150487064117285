import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

const connectivity: KeyValue[] = [
    { key: 'wired', value: 'Cableado' },
    { key: 'wireless', value: 'Inalámbrico' },
];

const connectionType: KeyValue[] = [
    { key: 'bluetooth', value: 'Bluetooth' },
    { key: 'jack', value: 'Jack 3.5mm' },
    { key: 'usb', value: 'USB' },
];

const lighting: KeyValue[] = [
    { key: 'argb', value: 'ARGB' },
    { key: 'rgb', value: 'RGB' },
    { key: 'fixedColor', value: 'Color Fijo' },
    { key: 'noBacklight', value: 'Sin retroiluminación' },
];

const sound: KeyValue[] = [
    { key: '5.1', value: '5.1' },
    { key: '7.1', value: '7.1' },
    { key: '2.0', value: '2.0' },
];

const format: KeyValue[] = [
    { key: 'headset', value: 'Headset' },
    { key: 'in-ear', value: 'In-ear' },
];

export default {
    connectivity,
    connectionType,
    withMicrophone: boolean,
    lighting,
    sound,
    noiseCancelling: boolean,
    format,
};
