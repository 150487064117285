import { createContext, useContext, useReducer } from 'react';

import API from '../../api/API';
import configReducer from './reducer';
import { ConfigContextType, ConfigItem, ConfigProviderProps, ConfigState, TypeAction, configKeys } from './types';

const initialState: ConfigState = {
    [configKeys.FREE_SHIPPING]: false,
};

export const ConfigContext = createContext<ConfigContextType>({
    state: initialState,
    dispatch: () => null,
});

const ConfigProvider = ({ children }: ConfigProviderProps) => {
    const [state, dispatch] = useReducer(configReducer, initialState);
    return <ConfigContext.Provider value={{ state, dispatch }}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
    const context = useContext(ConfigContext);
    const { state, dispatch } = context;

    const fetchConfigs = () => {
        API.configs
            .getAll()
            .then(({ data }: { data: { configs: ConfigItem[] } }) => {
                const config: ConfigState = data.configs.reduce<ConfigState>(
                    (acc: ConfigState, item: ConfigItem) => ({
                        ...acc,
                        [item.id]: item.value,
                    }),
                    initialState
                );
                dispatch({
                    type: TypeAction.FETCH_CONFIGS_SUCCESS,
                    config,
                });
            })
            .catch((error: unknown) => {
                console.error('Error fetching configuration:', error);
                dispatch({
                    type: TypeAction.FETCH_CONFIGS_ERROR,
                    config: initialState,
                });
            });
    };
    return {
        state,
        fetchConfigs,
    };
};

export default ConfigProvider;
