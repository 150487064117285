import { KeyValue } from '../../types/KeyValue';

const material: KeyValue[] = [
    { key: 'syntheticLeather', value: 'Cuero Sintético' },
    { key: 'fabric', value: 'Tela' },
];

const maximumSupportedWeight: KeyValue[] = [
    { key: '100kg', value: '100 kg' },
    { key: '120kg', value: '120 kg' },
    { key: '150kg', value: '150 kg' },
];

export default {
    material,
    maximumSupportedWeight,
};
